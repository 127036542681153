<template>
	<v-card
		:max-width="maxWidth"
		:max-height="maxHeight"
		class="alert-dialog mx-auto"
	>
		<span class="icon-big-check" style="font-size:70px" />
		<div class="alert-dialog__title text-center">
			{{ title || 'Successfully' }}
		</div>
		<div
			v-if="message"
			class="alert-dialog__message text-center"
			v-html="message"
		/>
		<slot v-else name="message_box">
			<div class="alert-dialog__message text-center">
				This action cannot be undone
			</div>
		</slot>
		<slot name="info_box" />
		<v-row no-gutters class="alert-dialog__action">
			<v-col class="alert-dialog__action--close mr-2">
				<v-btn
					height="48px"
					depressed
					block
					color="#F0F1F3"
					class="button-action"
					@click="onClickNegative"
				>
					{{ negativeText || 'Cancel' }}
				</v-btn>
			</v-col>
			<v-col class="alert-dialog__action--ok ml-2">
				<v-btn
					height="48px"
					depressed
					block
					:is-loading="isLoading"
					color="primary"
					class="button-action"
					@click="onClickPositive"
				>
					{{ positiveText || 'Yes' }}
				</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		message: {
			type: String,
			default: null,
		},
		positiveText: {
			type: String,
			default: 'You cannot undo this',
		},
		positiveCb: {
			type: Function,
			default: () => {},
		},
		negativeText: {
			type: String,
			default: 'You cannot undo this',
		},
		negativeCb: {
			type: Function,
			default: () => {},
		},
		maxHeight: {
			type: String,
			default: '350px',
		},
		maxWidth: {
			type: String,
			default: '438px',
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		onClickNegative() {
			if (this.negativeCb) {
				this.negativeCb()
			}
			this.$store.dispatch('alert/setCloseAlert')
		},
		onClickPositive() {
			if (this.positiveCb) {
				this.positiveCb()
				// if positiveCb != null it will be return in above function
				this.$emit('onSubmit')
			}
			this.$store.dispatch('alert/setCloseAlert')
		},
	},
}
</script>
