import Api from '~/helper/api'
const getDefaultState = () => {
	return {
		news: {
			id: null,
			category: null,
			title: null,
			content: null,
			created_at: null,
			updated_at: null,
			author: null,
		},
		newsList: [],
		loading: false,
		error: null,
	}
}

const state = () => getDefaultState()

const getters = {
	getNews: state => state.news,
	getNewsList: state => state.newsList,
	getLoading: state => state.loading,
	getError: state => state.error,
}

const actions = {
	async fetch({ commit }) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('news-fetch'))
			if (res.status === 200) {
				const data = res.data.data
				commit('setNewsList', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async show({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('news-show', payload.id))
			if (res.status === 200) {
				const data = res.data.data
				commit('setNews', data)
			}
		} catch (err) {
			// console.log(err)
			commit('setError', err)
		} finally {
			commit('setLoading', false)
		}
	},
	async create({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(Api('news-create'), {
				...payload.news,
			})
			if (res.status === 200) {
				const data = res.data.data
				this.$router.push({
					name: 'news-id-edit',
					params: { id: data.id },
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async update({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(Api('news-update', payload.id), {
				...payload.news,
			})
			if (res.status === 200) {
				this.$router.push({
					name: 'news-id-edit',
					params: { id: payload.id },
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	resetState({ commit }) {
		commit('resetState')
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setNews(state, data) {
		state.news = { ...data }
	},
	setNewsTitle(state, data) {
		state.news.title = data
	},
	setNewsCategory(state, data) {
		state.news.category = data
	},
	setNewsContent(state, data) {
		state.news.content = data
	},
	setNewsList(state, data) {
		state.newsList = [...data]
	},
	setLoading(state, loading) {
		state.loading = loading
	},
	setError(state, data) {
		state.error = { ...data }
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
