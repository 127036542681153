import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'

// or with custom config
Vue.use(VueCurrencyFilter, [
	{
		symbol: 'đ',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: '.',
		symbolPosition: 'back',
		symbolSpacing: true,
	},
	{
		name: 'format_thousand_number',
		thousandsSeparator: ',',
	},
])
