<template>
	<v-app id="app">
		<app-bar />
		<sidebar />
		<v-main class="app-container">
			<!-- <notice-bar /> -->
			<okxe-tabs
				v-if="tabs.length > 0"
				style="padding: 0px !important"
				is-visible-btn-next-tabs
				:items="tabs"
				:tab-active="tabActive"
				class="transaction-tabs"
			/>
			<v-container class="fill-height pa-0">
				<transition name="slide-fade">
					<nuxt />
				</transition>
			</v-container>
		</v-main>
		<alert />
		<alert-v2 />
		<quick-message />
		<v-overlay :value="overlay" z-index="9999">
			<v-progress-circular indeterminate size="64" />
		</v-overlay>
	</v-app>
</template>

<script>
import AppBar from '~/components/shared/AppBar'
import Sidebar from '~/components/shared/Sidebar'
import NoticeBar from '~/components/NoticeBar'
import Alert from '~/components/alert/AlertComponent'
import AlertComponentV2 from '~/components/alert/AlertComponentV2'
import QMessageComponent from '~/components/qMessage/QMessageComponent'
import OkxeTabs from '~/components/OkxeTabs'

export default {
	components: {
		AppBar,
		Sidebar,
		NoticeBar,
		Alert,
		'alert-v2': AlertComponentV2,
		'quick-message': QMessageComponent,
		OkxeTabs,
	},

	middleware: ['log', 'checkAuth', 'isAuth', 'checkPassword'],

	data() {
		return {
			// windowSize: {
			// 	x: 0,
			// 	y: 0
			// }
		}
	},

	computed: {
		overlay() {
			return this.$store.getters['common/getOverlay']
		},
		tabs() {
			return this.$store.getters['tabs/getTabs']
		},
		tabActive() {
			return this.$store.getters['tabs/getTabActice']
		},
		// tabs() {
		// 	if (this.paymentMethod === 2) {
		// 		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		// 		this.initTabs.pop()
		// 	} else {
		// 		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		// 		this.initTabs.splice(1, 1)
		// 	}
		//     return this.initTabs
		// }
	},

	// mounted() {
	// 	this.onResize()
	// },

	methods: {
		// onResize() {
		// 	this.windowSize = { x: window.innerWidth, y: window.innerHeight }
		// 	console.log(this.windowSize)
		// }
	},
}
</script>

<style lang="scss">
.transaction-tabs {
	position: absolute;
	left: 0;
	top: 50px;
}
</style>
