<template>
	<v-app-bar
		v-bind="$attrs"
		app
		:flat="true"
		fixed
		height="80"
		color="white"
		class="app-bar"
	>
		<!-- logo item on left side -->
		<slot name="logo">
			<v-app-bar-nav-icon @click.stop="onClickToggleDrawer" />
		</slot>
		<v-spacer />
		<slot name="right-items">
			<!-- <span class="app-bar__username">trangvu@okxe.com</span>
				<v-btn
					text
					small
					color="primary"
					@click="onClickLogout"
				>
					logout
				</v-btn> -->

			<v-toolbar-items class="app-bar__right-items">
				<!-- <span class="app-bar__username">
					trangvu@okxe.com
				</span> -->
				<!-- <div class="app-bar__divider mx-5 " /> -->
				<v-btn max-height="40px" small icon @click="onClickQR">
					<v-icon>mdi-qrcode-scan</v-icon>
				</v-btn>
				<div class="app-bar__divider mx-5 " />
				<I18nSwitcher />
				<div class="app-bar__divider mx-5 " />
				<v-btn
					text
					small
					max-height="20px"
					color="primary"
					@click="onClickLogout"
				>
					Log out
				</v-btn>
			</v-toolbar-items>
		</slot>
		<notice-bar v-if="notice" style="top: 80px;" />
	</v-app-bar>
</template>

<script>
import I18nSwitcher from '~/components/I18nSwitcher'
import NoticeBar from '~/components/NoticeBar'
export default {
	components: {
		NoticeBar,
		I18nSwitcher,
	},

	props: {
		notice: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {}
	},

	computed: {
		drawer() {
			return this.$store.getters['setting/getDrawer']
		},
	},

	methods: {
		onClickLogout() {
			this.$store.dispatch('auth/logout')
		},
		onClickToggleDrawer() {
			this.$store.dispatch('setting/toggleDrawer')
			// this.drawer = !this.drawer
		},
		onClickQR() {
			this.$router.push({ name: 'qr-scanners' })
		},
	},
}
</script>
