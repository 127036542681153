const state = () => ({
	items: [],
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},
}

const getters = {
	getItems: state => state.items,
}

const actions = {}

export default {
	state,
	actions,
	mutations,
	getters,
}
