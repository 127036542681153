const getDefaultState = () => {
	return {
		dialog: false,
		type: null,
		title: null,
		message: null,
		persistent: false,
		positiveText: null,
		positiveCb: null,
		negativeText: null,
		negativeCb: null,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getDialog: state => state.dialog,
	getType: state => state.type,
	getTitle: state => state.title,
	getMessage: state => state.message,
	getPersistent: state => state.persistent,
	getPositiveText: state => state.positiveText,
	getPositiveCb: state => state.positiveCb,
	getNegativeText: state => state.negativeText,
	getNegativeCb: state => state.negativeCb,
}

const mutations = {
	/**
	 * set state to default
	 */
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	/**
	 * alert popup content
	 * with diffrent type, message and onClick button
	 * @param {boolean} dialog on/off dialog
	 * @param {string} type with 3 type confirm/error/success
	 * @param {string} title dialog's title
	 * @param {string} message dialog's body message
	 * @param {boolean} persistent Clicking outside of the element will not deactivate it. Refer https://vuetifyjs.com/en/components/dialogs
	 * @param {string} positiveText text in yes button
	 * @param {function} positiveCb dialog's positive callback
	 * @param {string} negativeText text in no button
	 * @param {function} negativeCb dialog's negative callback
	 */
	SET_ALERT(state, payload) {
		const {
			dialog,
			type,
			title,
			message,
			persistent,
			positiveText,
			positiveCb,
			negativeText,
			negativeCb,
		} = payload
		state.dialog = dialog || false
		state.type = type || null
		state.title = title || null
		state.message = message || null
		state.persistent = persistent || false
		state.positiveText = positiveText || null
		state.positiveCb = positiveCb || null
		state.negativeText = negativeText || null
		state.negativeCb = negativeCb || null
	},
	/**
	 * set state.dialog to false
	 */
	SET_CLOSE_ALERT(state) {
		state.dialog = false
	},
}

const actions = {
	/**
	 * action open alert popup
	 * reset last state when open
	 */
	setAlert({ commit }, payload) {
		commit('RESET_STATE')
		commit('SET_ALERT', payload)
	},
	/**
	 * action close alert popup
	 */
	setCloseAlert({ commit }) {
		commit('SET_CLOSE_ALERT')
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
