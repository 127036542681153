/* eslint-disable no-empty-pattern */
import Api from '~/helper/api'

const state = () => ({
	customerList: [],
	userList: [],
	vehiclesList: null,
	vehiclesDetail: null,
	historyWarrantyList: null,
	stationList: [],
	isLoading: false,
	isLoadingSearchCustomer: false,
	packageMncList: [],
	isLoadingDialogLink: false,
	// processSuccess: false,
	pagination: {
		current: 1,
		total: 0,
		perPage: 50,
	},
})

const getters = {
	getLoadingDialogLink: state => state.isLoadingDialogLink,
	getLoadingSearchCustomer: state => state.isLoadingSearchCustomer,
	getCustomerList: state => state.customerList,
	getUserList: state => state.userList,
	getPackageMncList: state => state.packageMncList,
	getvehiclesList: state => state.vehiclesList,
	getStationList: state =>
		state.stationList.map(item => {
			return {
				text: item.name + ' - ' + item.location,
				value: item.id,
			}
		}),
	getLoading: state => state.isLoading,
	getVehiclesDetail: state => state.vehiclesDetail,
	getHistoryWarrantyList: state => state.historyWarrantyList,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
}

const actions = {
	async vehiclesDetail({ commit }, param) {
		try {
			commit('SET_LOADING', true)
			const [
				vehicelsDetailResponse,
				historyWarrantyResponse,
				stationListResponse,
			] = await Promise.all([
				this.$axios.get(Api('fetch-vehicles-detail', param)),
				this.$axios.get(Api('fetch-machine-service-history', param)),
				this.$axios.get(Api('store-search'), {
					params: {
						is_station: 2,
					},
				}),
			])
			if (vehicelsDetailResponse.data.result_code === 1) {
				commit('SET_VEHICLES_DETAIL', vehicelsDetailResponse.data.data)
			} else {
				commit('SET_VEHICLES_DETAIL', null)
			}
			if (historyWarrantyResponse.data.result_code === 1) {
				commit(
					'SET_HISTORY_WARRANTY',
					historyWarrantyResponse.data.data.data
				)
			} else {
				commit('SET_HISTORY_WARRANTY', null)
			}

			if (stationListResponse.data.result_code === 1) {
				commit('SET_STATION_LIST', stationListResponse.data.data)
			}
		} catch (error) {
			console.log(error)
		} finally {
			commit('SET_LOADING', false)
		}
	},

	async updateWarranty({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING', true)
			const res = await this.$api.warranty.update(
				payload.id,
				payload.id_mcn,
				payload.formData
			)

			if (res.data.result_code !== 1) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: res.data.result,
						title: 'error',
						positiveCb: () => {
							this.dialog = false
						},
					},
					{ root: true }
				)
			} else {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: 'Product register successfully!',
						title: 'Successfully',
						positiveCb: () => {
							this.$router.go(this.$router.currentRoute)
						},
					},
					{ root: true }
				)
			}
		} catch (error) {
			console.log(error)
		} finally {
			commit('SET_LOADING', false)
		}
	},

	async search({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_SEARCH_CUSTOMER', true)
			const res = await this.$axios.get(Api('search-customer'), {
				params: {
					...payload.params,
				},
			})
			if (res.data.result_code === 1) {
				const data = res.data.data
				commit('SET_CUSTOMER_LIST', data)
			}
		} catch (err) {
			if (
				err &&
				err.message === 'Operation canceled by the Route change.'
			) {
				console.log('Operation canceled by the Route change.')
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('SET_LOADING_SEARCH_CUSTOMER', false)
		}
	},

	async searchUser({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING_DIALOG_LINK', true)

			const res = await this.$axios.get(Api('search-user'), {
				params: {
					...payload.params,
					// count: 50,
				},
			})
			if (res.data.result_code === 1) {
				const data = res.data.data
				commit('SET_USER_LIST', data)
			}
		} catch (err) {
			if (
				err &&
				err.message === 'Operation canceled by the Route change.'
			) {
				console.log('Operation canceled by the Route change.')
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('SET_LOADING_DIALOG_LINK', false)
		}
	},

	async fetchPackageList({ commit }) {
		try {
			commit('SET_LOADING', true)
			const res = await this.$api.warranty.fetchListPackageMcn()
			if (res.data.result_code === 1) {
				const data = res.data.data
				commit('SET_PACKAGE_MCN_LIST', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('SET_LOADING', false)
		}
	},
}

const mutations = {
	// resetState(state) {
	// 	Object.assign(state, getDefaultState())
	// },
	SET_LOADING_DIALOG_LINK(state, payload) {
		state.isLoadingDialogLink = payload
	},
	SET_STATION_LIST(state, payload) {
		state.stationList = payload
	},
	SET_VEHICLES_LIST(state, payload) {
		state.vehiclesList = payload
	},

	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},
	setCurrentPage(state, payload) {
		state.pagination.current = payload.current
	},
	SET_LOADING(state, loading) {
		state.isLoading = loading
	},

	SET_LOADING_SEARCH_CUSTOMER(state, loading) {
		state.isLoadingSearchCustomer = loading
	},

	SET_VEHICLES_DETAIL(state, payload) {
		state.vehiclesDetail = payload
	},
	SET_HISTORY_WARRANTY(state, payload) {
		state.historyWarrantyList = payload
	},
	SET_CUSTOMER_LIST(state, payload) {
		state.customerList = [...payload]
	},
	SET_PACKAGE_MCN_LIST(state, payload) {
		state.packageMncList = [...payload]
	},
	SET_USER_LIST(state, payload) {
		state.userList = [...payload]
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
