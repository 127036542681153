const resource = '/faqs'
const resourceCate = '/category-faqs'
export default $axios => ({
	createCategory(payload) {
		return $axios.post(`${resource}`, payload)
	},

	fetchCategoryLv(id, payload) {
		return $axios.get(`${resource}/category/level/${id}`)
	},
	// faq management
	createFAQ(payload) {
		return $axios.post(`${resource}`, payload)
	},

	showFAQ(id, payload) {
		return $axios.get(`${resource}/${id}`, payload)
	},

	fetchCategory() {
		return $axios.get(`${resource}/category`)
	},

	updateFAQ(id, payload) {
		return $axios.post(`${resource}/${id}`, payload)
	},
	fetchCategoryLevel(id, payload) {
		return $axios.get(`${resourceCate}/level?level=${id}`)
	},
	fetchPositionOfCategory(id, payload) {
		return $axios.get(`${resourceCate}/${id}/faq`)
	},
})
