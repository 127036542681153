const resource = '/transactions'
const resourceLoanInfoTrans = '/transactions'
const resourceLoanDisbursed = '/loans'
const resourceBank = '/banks'
const newResource = '/v2/transactions'

export default $axios => ({
	fetchTransaction(payload) {
		return $axios.get(`${resource}`, { ...payload })
	},
	fetchTransactionV2(payload) {
		return $axios.get(`${newResource}`, { ...payload })
	},
	changeStatus(id, payload) {
		return $axios.put(`${resource}/${id}/change-status`, { ...payload })
	},
	changeStatusV2(id, payload) {
		return $axios.put(`v2${resource}/${id}/change-status`, { ...payload })
	},
	fetchLoanInfoTrans(id) {
		return $axios.get(`${resource}/${id}/e-contract`)
	},

	fectLoanTransactionDetails(id, payload) {
		return $axios.get(`${resource}/${id}/detail`, { ...payload })
	},

	fectCashTransactionDetails(id) {
		return $axios.get(`${resource}/${id}/detail`)
	},

	dowloadPOTransactionDetail(id, value, text) {
		return $axios.get(
			`${resource}/loans/download-po/${id}/${value}/${text}`
		)
	},
	downloadPODetail(id) {
		return $axios.get(`${resource}/loans/download-po/${id}`)
	},

	findLoanPaperwork(id) {
		return $axios.get(
			`${resourceLoanInfoTrans}/paperworks/get-by-loan/${id}`
		)
	},

	findPaperworkHistory(id) {
		return $axios.get(
			`${resourceLoanInfoTrans}/paperworks/get-by-transaction/${id}`
		)
	},

	UpdateReasonPaperwork(id, payload) {
		return $axios.post(
			`${resourceLoanInfoTrans}/paperworks/${id}/confirm`,
			payload
		)
	},

	ConfirmLoanDisbursed(id, payload) {
		return $axios.post(
			`${resourceLoanInfoTrans}/loans/${id}/disbursement`,
			payload
		)
	},

	findLoanDisbursed(id) {
		return $axios.get(`${resourceLoanInfoTrans}/loans/${id}/disbursement`)
	},
	saveOkxeLoanDisburd(id, payload) {
		return $axios.post(
			`${resourceLoanInfoTrans}/loans/${id}/disbursement`,
			payload
		)
	},
	// confirm okxe Loan disbursed - used old version api
	confirmOkxeLoanDisbursed(id) {
		return $axios.post(`${resourceLoanDisbursed}/${id}/loan-disbursed`)
	},

	fetchLoanPaperwork(id) {
		return $axios.get(`${resource}/paper-works/get-by-loan/${id}`)
	},

	fetchPaperworkHistory(id) {
		return $axios.get(`${resource}/paper-works/get-by-transaction/${id}`)
	},

	fetchProductDetail(id) {
		return $axios.get(`/products/${id}`)
	},

	fetchEcontract(id) {
		return $axios.get(`${resource}/contracts/${id}`)
	},

	fetchBank(payload) {
		if (payload) {
			return $axios.get(`${resourceBank}`, { ...payload })
		} else {
			return $axios.get(`${resourceBank}`)
		}
	},

	updateStatus(payload) {
		return $axios.put(`${resource}/multi-change-status`, payload)
	},

	// fetch transaction detail v2
	fetchTransactionDetailByIdV2(id) {
		return $axios.get(`v2${resource}/${id}`)
	},

	// update pickup time
	updatePickupTime(id, payload) {
		return $axios.put(`v2${resource}/${id}/update-received-time`, payload)
	},
})
