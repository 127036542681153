import Vue from 'vue'
import moment from 'moment'

Vue.filter('uppercase', text => {
	if (text) {
		return text.toUpperCase()
	} else {
		return ''
	}
})

Vue.filter('capitalize', function(value) {
	if (!value) {
		return ''
	}
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('fullname', (arg1, arg2) => {
	return ((arg1 || '') + ' ' + (arg2 || '')).trim() || 'Unnamed'
})

Vue.filter('date', date => {
	if (date) {
		return moment(date).format('MMMM Do YYYY')
	} else {
		return ''
	}
})

Vue.filter('product_category', function(type) {
	if (!type) {
		return
	}

	const category = [
		{
			id: 'all',
			type: 0,
			name: 'MAtabBar.labelAll',
		},
		{
			id: 'scooter',
			type: 1,
			name: 'MAtabBar.labelSc',
		},
		{
			id: 'gear',
			type: 2,
			name: 'MAtabBar.labelGear',
		},
		{
			id: 'pkl',
			type: 3,
			name: 'MAtabBar.labelLarge',
		},
		{
			id: 'electric',
			type: 4,
			name: 'MAtabBar.labelElec',
		},
	]

	const result = category.find(e => e.type === parseInt(type))

	return result ? result.id : type
})

Vue.filter('distance', value => {
	// if (!value || value === 0) {
	// 	return '_'
	// }

	if (!value) {
		return '_'
	}

	if (value < 0) {
		return '~300,000<span style="font-weight:600">\u339E</span>'
	}

	return (
		Math.sign(value) * Math.abs(value).toFixed(1) +
		' ' +
		'<span style="font-weight:600">\u339E</span>'
	)
})

Vue.filter('shortenNumber', value => {
	if (!value || value === 0) {
		return '_'
	}

	let result = value

	if (Math.abs(value) > 999) {
		result = Math.sign(value) * Math.abs(value).toFixed(1)
	} else {
		result = Math.sign(value) * Math.abs(value)
	}
	return result > 9
		? result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + 'đ'
		: result + 'đ'
})

Vue.filter('formatNumber', value => {
	if (!value || value === 0) {
		return '_'
	}

	let result = value

	if (Math.abs(value) > 999) {
		result = Math.sign(value) * Math.abs(value).toFixed(1)
	} else {
		result = Math.sign(value) * Math.abs(value)
	}
	return result > 9
		? result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		: result
})

Vue.filter('addCharater', (value, char) => {
	if (!value) {
		return '_'
	}

	return value + ' ' + char
})

Vue.filter('removeCharacters', value => {
	if (!value) {
		return
	}
	const result = value.replace(/\D/g, '')
	return result
})

Vue.filter('separateNumber', value => {
	if (!value) {
		return
	}

	// return parseInt(value).toLocaleString()
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})
