export default function({ store, redirect }) {
	const userInfo = store.getters['auth/getUserInfo']

	if (!userInfo) {
		return redirect('/login')
	}

	const firstLogin = parseInt(userInfo.first_login)
	if (firstLogin !== 0) {
		switch (firstLogin) {
			case 1:
				redirect('/login/change-password')
				break

			default:
				console.error('Error in checking password')
				break
		}
	}
}
