import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		role: null,
		roleList: [],
		loading: false,
		rolePermissions: [],
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getRole: state => state.role,
	getRoleList: state => state.roleList,
	getLoading: state => state.loading,
}

const actions = {
	async fetch({ commit }) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api['role-fetch'])
			if (res.status === 200) {
				const data = res.data
				commit('setRoleList', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async show({ commit }, payload) {
		//
		if (!payload.id) {
			return
		}

		try {
			commit('setLoading', true)
			const res = await this.$axios.get(
				Api['role-fetch'] + `/${payload.id}`
			)
			if (res.status === 200) {
				console.log(res)
				commit('setRole', res.data.data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async create({ commit }, payload) {
		try {
			commit('setLoading', true)
			await this.$axios.$post('/roles', {
				name: payload.roleName,
			})
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setRole(state, data) {
		state.role = { ...data }
	},
	setRoleList(state, list) {
		state.roleList = [...list]
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
