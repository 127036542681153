import Api from '~/helper/api'

const state = () => ({
	items: [],
	loading: false,
	pagination: {
		current: 1,
		total: 0,
		perPage: 1,
	},
	eventDetail: null,
	loadingCheckTitle: false,
	slugEvent: '',
	conditionEvent: false,
	isDeleteEvent: false,
	isChangeStatus: false,
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current = payload.current
	},
	SET_ITEM(state, payload) {
		state.eventDetail = payload
	},
	SET_LOADING_SEO_TITLE(state, payload) {
		state.loadingCheckTitle = payload
	},
	SET_SLUG_EVENT(state, payload) {
		state.slugEvent = payload
	},
	SET_CONDITION_EVENT(state, payload) {
		state.conditionEvent = payload
	},
	SET_DELETE_EVENT(state, payload) {
		state.isDeleteEvent = payload
	},
	SET_STATUS_EVENT(state, payload) {
		state.isChangeStatus = payload
	},
	RESET_EVENT(state) {
		state.items = []
		state.loading = false
		state.pagination = {
			current: 1,
			total: 0,
			perPage: 1,
		}
		state.eventDetail = null
		state.loadingCheckTitle = false
		state.slugEvent = ''
		state.conditionEvent = false
		state.isDeleteEvent = false
		state.isChangeStatus = false
	},
}

const getters = {
	getItems: state => state.items,
	getLoading: state => state.loading,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getItem: state => state.eventDetail,
	getLoadingCheckTitle: state => state.loadingCheckTitle,
	getSlug: state => state.slugEvent,
	getCondition: state => state.conditionEvent,
	getDeleteEvent: state => state.isDeleteEvent,
	getStatusEvent: state => state.isChangeStatus,
}

const actions = {
	async fetch({ commit }, payload) {
		commit('SET_LOADING', true)
		try {
			const res = await this.$axios.get(Api('event-fetch'), {
				params: {
					...payload.params,
				},
			})

			if (res.status === 200) {
				const data = res.data.data
				commit('SET_ITEMS', data)
				commit('SET_PAGINATION', {
					current: res.data.current,
					total: res.data.total,
					perPage: 1,
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('SET_LOADING', false)
		}
	},
	async create({ dispatch }, payload) {
		try {
			dispatch('common/setOverlay', true, { root: true })
			const res = await this.$axios.post(
				Api('event-create'),
				payload.form,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			if (res.status === 200) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: 'Tạo thành công',
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
							this.$router.push({ name: 'events' })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: err },
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},
	async fetchIdEvent({ commit }, payload) {
		const id = payload.params.id
		// commit('SET_LOADING', true)
		try {
			const res = await this.$axios.get(Api('event-fetch-id', id))
			if (res.status === 200) {
				const data = res.data.data
				commit('SET_ITEM', data)
				commit('SET_SLUG_EVENT', data.slug)
			}
		} catch (err) {
			console.log(err)
		}
	},
	async update({ dispatch }, payload) {
		try {
			dispatch('common/setOverlay', true, { root: true })
			const res = await this.$axios.post(
				Api('event-update', payload.id),
				payload.formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			if (res.status === 200) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: 'Sửa thành công',
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
							this.$router.push({ name: 'events' })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: err },
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},
	async checkTitle({ commit }, payload) {
		try {
			commit('SET_LOADING_SEO_TITLE', true)
			const res = await this.$axios.get(Api('event-check-title'), {
				params: {
					...payload.params,
				},
			})
			if (res.status === 200) {
				commit('SET_LOADING_SEO_TITLE', false)
				commit('SET_SLUG_EVENT', res.data.data.slug)
				commit('SET_CONDITION_EVENT', res.data.data.condition)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('SET_LOADING_SEO_TITLE', false)
		}
	},
	async delete({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING', true)
			const res = await this.$axios.delete(
				Api('event-delete', payload.params.id)
			)
			if (res.status === 200 && res.data.result_code === 1) {
				// commit('SET_LOADING', false)
				// commit('SET_DELETE_EVENT', true)
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: res.data.messages || 'Successfully!',
					},
					{ root: true }
				)
			} else {
				let error = ''
				for (const [, value] of Object.entries(res.data.data)) {
					error = error + value
				}

				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: error || 'Error. Something when wrong!',
					},
					{ root: true }
				)
			}
		} catch (err) {
			if (err.data && err.data.result_code !== 403) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('SET_LOADING', false)
		}
	},
	async updateStatus({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING', true)
			const res = await this.$axios.post(
				Api('event-update-status', payload.params.id),
				{ status: payload.params.status }
			)
			if (res.status === 200 && res.data.result_code === 1) {
				// commit('SET_STATUS_EVENT', true)
				// commit('SET_LOADING', false)
				// dispatch('alert/setAlert', {
				//     dialog: true,
				//     type: 'success',
				//     message: res.data.messages || 'Successfully!'
				// }, { root: true })
			} else {
				let error = ''
				for (const [, value] of Object.entries(res.data.data)) {
					error = error + value
				}

				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message:
							res.data.result || 'Error. Something when wrong!',
					},
					{ root: true }
				)
			}
			commit('SET_STATUS_EVENT', true)
			commit('SET_LOADING', false)
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('SET_LOADING', false)
		}
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
