import Cookie from 'js-cookie'

import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		isAuth: false,
		authToken: '',
		userId: '',
		userInfo: {
			id: null,
			nickname: null,
			first_login: null,
			email: null,
			avatar: null,
			username: null,
			role: null,
		},
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getUserId: state => state.userId,
	getAuthToken: state => state.authToken,
	isAuth: state => state.isAuth,
	getUserInfo: state => state.userInfo,
}

const actions = {
	login({ commit }, payload) {
		if (!payload) {
			return false
		}
		this.$axios.setToken(payload.access_token, 'Bearer')

		if (process.client) {
			Cookie.set('token', payload.access_token)
			Cookie.set('userId', payload.userId)
		}

		commit('setIsAuth', true)
		commit('setAuthToken', payload.access_token)
		commit('setUserId', payload.userId)
		// commit('setUserInfo', payload.user)
		// await dispatch('getUserInfo')

		this.$router.push('/')
	},

	checkAuth({ commit }) {
		if (Cookie.remove('token')) {
			commit('setIsAuth', true)
		} else {
			commit('setIsAuth', false)
		}
	},

	async getUserInfo({ commit }) {
		try {
			const res = await this.$axios.get(Api('users-get-info'))
			const data = res.data.data
			commit('setUserInfo', data)
		} catch (err) {
			console.log(err)
		}
	},

	async logout({ commit }) {
		try {
			await this.$axios.post(Api('auth-logout'))
		} catch (err) {
			console.log(err)
		} finally {
			this.$axios.setToken(false)
			Cookie.remove('token')
			Cookie.remove('userId')
			commit('resetState')
			this.$router.push('/login')
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setIsAuth(state, isAuth) {
		state.isAuth = isAuth
	},
	setUserId(state, userId) {
		state.userId = userId
	},
	setAuthToken(state, token) {
		state.authToken = token
	},
	setUserInfo(state, data) {
		state.userInfo = { ...data }
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
