
const getDefaultState = () => {
	return {
		res: null
	}
}

const state = () => ({
	...getDefaultState()
})

const getters = {
	getRes: state => state.res
}

const actions = {
	async fetch({ commit, rootGetters }, payload) {
		try {
			const res = await this.$axios.get('http://www.mocky.io/v2/5e4f3f533000003c1d226809?mocky-delay=3000ms', {
				cancelToken: payload.axiosSource.token
			})
			if (res.status === 200) {
				commit('setRes', res)
			}
		} catch (err) {
			console.log(err)
		}
	}
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setRes(state, payload) {
		state.res = payload
	}
}

export default {
	state,
	actions,
	mutations,
	getters
}
