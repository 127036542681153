const resource = '/news'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},

	show(id, payload) {
		return $axios.get(`${resource}/${id}`, payload)
	},

	create(payload, setting) {
		return $axios.post(`${resource}`, payload, setting)
	},

	update(id, payload) {
		return $axios.put(`${resource}/${id}`, payload)
	},

	patch(id, payload) {
		return $axios.patch(`${resource}/${id}`, payload)
	},

	delete(id) {
		return $axios.delete(`${resource}/${id}`)
	},

	search(payload) {
		return $axios.get(`${resource}/related`, payload)
	},
})
