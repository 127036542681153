const resourceSpecs = '/v2/model-specs'
const resourceSpecsDetail = '/v2/model-spec-detail'
const resourceBrands = '/v2/brands'
const resourceModels = '/models'
const resourceModelDetails = '/v2/model-details'
export default $axios => ({
	fetchBrands() {
		return $axios.get(`${resourceBrands}`)
	},
	fetchSpecs() {
		return $axios.get(`${resourceSpecs}`)
	},
	fetchModels(payload) {
		return $axios.get(
			`${resourceBrands}/` + payload.params.brand_id + `${resourceModels}`
		)
	},
	fetchModelDetails(payload) {
		return $axios.get(`${resourceModelDetails}`, { ...payload })
	},
	findModelDetails(id) {
		return $axios.get(`${resourceModelDetails}/${id}/edit`)
	},
	fetchProductCount(id) {
		return $axios.get(`${resourceModelDetails}/${id}/product-count`)
	},
	createSpecsDetail(payload) {
		return $axios.post(`${resourceSpecsDetail}`, payload)
	},
	createNewSpecs(payload) {
		return $axios.post(`${resourceSpecs}`, payload)
	},

	updateStatusModelDetails(id, payload) {
		return $axios.put(`${resourceModelDetails}/${id}/change`, {
			...payload,
		})
	},
	createModelDetails(payload) {
		return $axios.post(`${resourceModelDetails}`, payload)
	},
	updateModelDetails(id, payload) {
		return $axios.post(`${resourceModelDetails}/${id}`, payload)
	},
	deleteModelDetails(id, newId = null, payload) {
		if (newId) {
			return $axios.delete(`${resourceModelDetails}/${id}/${newId}`)
		} else {
			return $axios.delete(`${resourceModelDetails}/${id}`, payload)
		}
	},
})
