<template>
	<v-navigation-drawer
		v-model="drawer"
		v-bind="$attrs"
		class="sidebar"
		:color="color"
		width="220"
		mobile-breakpoint="960"
		disable-resize-watcher
		fixed
		app
		dark
	>
		<template #prepend>
			<div class="sidebar__logo">
				<img src="/images/okxe-logo-v2.svg" height="60" width="60" />
				<span class="sidebar__logo-okxe">OKXE</span>
				<span class="sidebar__logo-admin">Admin</span>
			</div>
			<user-block />
		</template>
		<div class="sidebar__nav">
			<v-list>
				<div v-for="(item, index) in items" :key="index">
					<v-list-group
						v-if="
							item.child &&
								userInfo.role &&
								userInfo.role.length > 0 &&
								showSidebar(item.permissions)
						"
						active-class="group-menu-active"
						color="white"
					>
						<template #activator>
							<v-list-item-title v-text="item.title" />
						</template>
						<v-list-item
							v-for="(child, i) in item.child"
							:key="i"
							v-permission="{
								permission: child.permission,
								userInfo: userInfo,
							}"
							:to="child.to"
							router
							exact
						>
							<v-list-item-title
								class="px-o-10"
								v-text="child.title"
							/>
						</v-list-item>
					</v-list-group>
					<v-list-item
						v-else
						v-permission="{
							permission: item.permission,
							userInfo: userInfo,
						}"
						nuxt
						:to="item.to"
						router
					>
						<v-list-item-content>
							<v-list-item-title v-text="item.title" />
						</v-list-item-content>
					</v-list-item>
				</div>
			</v-list>
		</div>
		<div class="sidebar__footer">
			<div class="sidebar__footer-brand">
				OKXE.vn
			</div>
			<div class="sidebar__footer-operator">
				<span>대표자 : Wooseok Kim</span><br />
				<span>사이트 담당 관리자 : Trang Vu</span>
			</div>
		</div>
		<!-- <template #append>
			<button
				class="sidebar__button-toggle"
				:class="[drawer ? 'show' : 'hide']"
				@click="onClickToggleDrawer"
			>
				<v-icon
					color="success"
					:style="drawer ? 'transform: rotate(-180deg)' : ''"
				>
					chevron_right
				</v-icon>
			</button>
		</template> -->
	</v-navigation-drawer>
</template>

<script>
import UserBlock from '~/components/UserBlock'

export default {
	components: {
		UserBlock,
	},

	data() {
		return {
			color: '#2c2c2c',
			// drawer: false,
			items: [
				{
					title: 'Dashboard',
					to: '/',
				},
				{
					title: 'Admin User Management',
					to: '',
					permissions: ['users-index'],
					child: [
						{
							title: 'Users',
							to: '/user-managers',
							permission: 'users-index',
						},
						{ title: 'Role & Permission', to: '/role-permission' },
					],
				},
				{
					title: 'User Management',
					to: '/stores',
					permission: 'stores-index',
				},
				{
					title: 'Transaction Management',
					to: '/transactions',
					permission: 'transaction-index',
				},
				{
					title: 'Loan Management',
					to: '/finance-loans',
					permission: 'cash-loans-index',
				},
				{
					title: 'Product Management',
					to: '',
					permissions: ['products-list', 'products-store'],
					child: [
						{
							title: 'Register Product',
							to: '/products/register',
							permission: 'products-store',
						},
						{
							title: 'Products',
							to: '/products',
							permission: 'products-list',
						},
					],
				},
				{
					title: 'Station Management',
					to: '',
					permissions: ['products-list', 'products-store'],
					child: [
						{
							title: 'Register Product',
							to: '/stations/register',
							permission: 'products-store',
						},
						{
							title: 'Station Product',
							to: '/stations',
							permission: 'products-list',
						},
						{
							title: 'Warranty',
							to: '/stations/vehicles',
							permission: 'products-list',
						},
						{
							title: 'Orders',
							to: '/stations/orders',
							permission: 'products-list',
						},
					],
				},
				{
					title: 'Brand & model Management',
					to: '/brands',
					permission: ['model-details-index'],
				},
				// {
				// 	title: 'Reviews Management',
				// 	to: '/reviews'
				// },
				{
					title: 'News Management',
					to: '',
					permission: [
						'news-list',
						'banners-index',
						'events-index',
						'popups-view',
					],
					child: [
						{
							title: 'All Articles',
							to: '/news',
							permission: 'news-list',
						},
						{
							title: 'Categories',
							to: '/category/create',
							permission: 'category-index',
						},
						{
							title: 'Comments',
							to: '/comments',
							permission: 'comment-index',
						},
					],
				},
				{
					title: 'Promotion Management',
					to: '',
					permissions: [
						'news-list',
						'banners-index',
						'events-index',
						'popups-view',
						'pages-view',
					],
					child: [
						{
							title: 'Banners',
							to: '/banners',
							permission: 'banners-index',
						},
						{
							title: 'Event',
							to: '/events',
							permission: 'events-index',
						},
						{
							title: 'Pop-up',
							to: '/popups',
							permission: 'popups-view',
						},
						{
							title: 'Page',
							to: '/pages',
							permission: 'pages-view',
						},
					],
				},
				{
					title: 'Ads Management',
					to: '',
					permissions: [
						'ads_settings-index',
						'statistics-index',
						'wallets-index',
						'advertises-index',
						'cash-orders-list',
					],
					child: [
						{
							title: 'Overview',
							to: '/promo-overview',
							permission: 'statistics-index',
						},
						{
							title: 'Ads Accounts',
							to: '/promo-accounts',
							permission: 'wallets-index',
						},
						{
							title: 'Ads Cash Order',
							to: '/promo-cashes',
							permission: 'cash-orders-list',
						},
						{
							title: 'Ads Alepay Order',
							to: '/promo-alepay',
							permission: 'cash-orders-list',
						},
						{
							title: 'Product Ads',
							to: '/promo-products',
							permission: 'advertises-index',
						},
						{
							title: 'Settings',
							to: '/promo-settings',
							permission: 'ads_settings-index',
						},
					],
				},
				{
					title: 'Lead Management',
					to: '/leads',
					permission: 'leads-view',
				},
				{
					title: 'Partner Management',
					to: '/partners',
					permission: 'partners-view',
				},
				{
					title: 'FAQs',
					to: '',
					permissions: ['faqs-index', 'category-faqs-index'],
					child: [
						{
							title: 'FAQs Managements',
							to: '/faqs',
							permission: 'faqs-index',
						},
						{
							title: 'FAQs Category Managements',
							to: '/faqs/category',
							permission: 'category-faqs-index',
						},
					],
				},
			],
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters['auth/getUserInfo']
		},
		drawer: {
			set: function(value) {
				this.$store.commit('setting/setDrawer', value)
			},
			get: function() {
				return this.$store.getters['setting/getDrawer']
			},
		},
	},

	created() {},

	mounted() {
		// eslint-disable-next-line no-unneeded-ternary
		this.drawer = window.innerWidth > 960
	},
	methods: {
		onClickToggleDrawer() {
			this.$store.dispatch('setting/toggleDrawer')
			// this.drawer = !this.drawer
		},
		// check the condition to show/hide the sidebar
		// condition to hide tab User Mangagement: role is member
		showSidebar(pers) {
			if (!pers) {
				return true
			}
			const self = this
			if (self.userInfo) {
				return (
					self.userInfo.role_has_permission === 1 ||
					self.userInfo.role_has_permission.some(x => {
						return pers.includes(x.name)
					})
				)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.sidebar {
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(255, 255, 255, 0.582);
		-webkit-box-shadow: 0 0 1px #2c2c2c;
	}
}
</style>
