const resource = '/v1/cash-loans'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},

	show(id) {
		return $axios.get(`${resource}/${id}`)
	},

	getHistory(id) {
		return $axios.get(`${resource}/${id}/histories`)
	},

	updateStatus(loanId, status) {
		return $axios.put(`${resource}/${loanId}/status/${status}`)
	},

	getDataNiceSync(loanId) {
		return $axios.put(`${resource}/${loanId}/update-scores`)
	},
})
