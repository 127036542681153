const getDefaultState = () => {
	return {
		axiosSource: null,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getAxiosSource: state => state.axiosSource,
	getCancelToken: state => {
		return state.axiosSource.token
	},
}

const actions = {
	cancelAllRequest({ dispatch, state }) {
		if (state.axiosSource) {
			state.axiosSource.cancel('Operation canceled by the Route change.')
			dispatch('refreshToken')
		}
	},
	refreshToken({ commit }) {
		commit('refreshToken', {
			source: this.$axios.CancelToken.source(),
		})
	},
}

const mutations = {
	resetState() {
		Object.assign(state, getDefaultState())
	},
	setAxiosSource(state, payload) {
		state.axiosSource = payload
	},
	refreshToken(state, payload) {
		state.axiosSource = payload.source
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
