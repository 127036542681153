const state = () => ({
	items: [],
	loading: false,
	pagination: {
		current: 1,
		total: 0,
		perPage: 1,
	},
	isChangeStatus: false,
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current = payload.current
	},
	SET_STATUS_EVENT(state, payload) {
		state.isChangeStatus = payload
	},
	RESET_EVENT(state) {
		state.items = []
		state.loading = false
		state.pagination = {
			current: 1,
			total: 0,
			perPage: 1,
		}
		state.eventDetail = null
		state.loadingCheckTitle = false
		state.slugEvent = ''
		state.conditionEvent = false
		state.isDeleteEvent = false
		state.isChangeStatus = false
	},
}

const getters = {
	getItems: state => state.items,
	getLoading: state => state.loading,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getStatusEvent: state => state.isChangeStatus,
}

const actions = {
	async fetch({ commit }, payload) {
		commit('SET_LOADING', true)
		try {
			const res = await this.$api.comments.find(payload)

			if (res.status === 200) {
				const data = res.data.data
				commit('SET_ITEMS', data)
				commit('SET_PAGINATION', {
					current: res.data.current,
					total: res.data.total,
					perPage: 1,
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('SET_LOADING', false)
		}
	},
	async updateStatus({ commit, dispatch }, payload) {
		try {
			commit('SET_LOADING', true)
			const res = await this.$api.comments.update(payload.params.id, {
				status: payload.params.status,
			})
			if (res.status === 200) {
				commit('SET_STATUS_EVENT', true)
				commit('SET_LOADING', false)
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('SET_LOADING', false)
		}
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
