import colors from 'vuetify/es5/util/colors'

const MY_ICONS = {}

export default function({ app }) {
	return {
		customVariables: ['~/assets/variables.sass'],
		icons: {
			iconfont: 'mdi',
			values: MY_ICONS,
		},
		theme: {
			light: true,
			themes: {
				light: {
					// Iris Blue
					primary: '#00bcc3',
					// Solitude grey
					secondary: '#BFBFBF',
					// black
					accent: colors.shades.black,
					// Iris Blue
					info: '#00bcc3',
					// yellow
					warning: colors.amber.base,
					// Shamrock green
					success: '#22e59f',
					// red
					error: colors.red.accent3,
					// grey
					hightligh: '#555555',
				},
				dark: {
					primary: colors.blue.darken2,
					secondary: colors.amber.darken3,
					accent: colors.grey.darken3,
					info: colors.teal.lighten1,
					warning: colors.amber.base,
					error: colors.deepOrange.accent4,
					success: colors.green.accent3,
				},
			},
		},
	}
}
