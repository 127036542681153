const resource = '/v2/banners'
const pageResource = '/page-banners'

export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	show(id) {
		return $axios.get(`${resource}/${id}`)
	},
	update(id, payload, headers) {
		// return $axios.put(`${resource}/${id}`, payload, headers)
		return $axios.post(`${resource}/${id}`, payload, headers)
	},
	create(payload) {
		return $axios.post(`${resource}`, payload)
	},
	getSlider(payload) {
		return $axios.get(`${resource}/list`, payload)
	},

	getPageBanners() {
		return $axios.get(`${pageResource}`)
	},
})
