const resourceV2 = '/v2/stores'
const resourceStoreDetail = 'v2/products'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resourceV2}`, payload)
	},

	show(id, payload) {
		return $axios.get(`${resourceV2}/${id}`, payload)
	},

	create(payload) {
		return $axios.post(`${resourceV2}`, payload)
	},

	update(id, payload) {
		return $axios.post(`${resourceV2}/${id}`, payload)
	},

	delete(id) {
		return $axios.delete(`${resourceV2}/${id}`)
	},

	updateStoreType(id, payload) {
		return $axios.put(`${resourceV2}/${id}/change-type`, payload)
	},

	fetchStoreDetail(id) {
		return $axios.get(`${resourceV2}/${id}`)
	},

	fectStoreInfo(id) {
		return $axios.get(`${resourceV2}/${id}`)
	},
	fetchStoreProducts(payload) {
		return $axios.get(`${resourceStoreDetail}`, payload)
	},
	fetchProductStatic(id) {
		return $axios.get(`${resourceStoreDetail}/statistic-product/${id}`)
	},
	updateStoreDetail(id, payload) {
		return $axios.put(`${resourceV2}/${id}`, payload)
	},
})
