const resource = '/pages'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	show(id) {
		return $axios.get(`${resource}/${id}`)
	},
	update(id, payload, headers) {
		return $axios.put(`${resource}/${id}`, payload, headers)
	},
	updateStatus(id, payload) {
		return $axios.put(`${resource}/${id}/change-status`, payload)
	},
	getPopups(id) {
		return $axios.get(`${resource}/${id}/popup`)
	},
	getBanners(id) {
		return $axios.get(`${resource}/${id}/banner`)
	},
	create(payload) {
		return $axios.post(`${resource}`, payload)
	},
	delete(id) {
		return $axios.delete(`${resource}/${id}`)
	},
})
