import Vue from 'vue'
import Quill from 'quill'
import VueQuillEditor from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import { htmlEditButton } from 'quill-html-edit-button'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

// -----------------------------------------------
const BlockEmbed = Quill.import('blots/block/embed')
// const Inline = Quill.import('blots/inline')

class ImageBlot extends BlockEmbed {
	static create(value) {
		const node = super.create()
		if (value.alt) {
			node.setAttribute('alt', value.alt)
		}
		node.setAttribute('src', value.src)
		return node
	}

	static value(node) {
		return {
			alt: node.getAttribute('alt'),
			url: node.getAttribute('src'),
		}
	}
}

ImageBlot.blotName = 'image'
ImageBlot.tagName = 'img'

Quill.register(ImageBlot)

const BaseImageFormat = Quill.import('formats/image')
const ImageFormatAttributesList = ['src', 'alt', 'height', 'width', 'style']

class ImageFormat extends BaseImageFormat {
	static formats(domNode) {
		return ImageFormatAttributesList.reduce(function(formats, attribute) {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute)
			}
			return formats
		}, {})
	}

	format(name, value) {
		if (ImageFormatAttributesList.includes(name)) {
			if (value) {
				this.domNode.setAttribute(name, value)
			} else {
				this.domNode.removeAttribute(name)
			}
		} else {
			super.format(name, value)
		}
	}
}

Quill.register(ImageFormat, true)

class LinkImage extends BlockEmbed {
	static create(value) {
		const node = super.create()
		node.setAttribute('href', value.url)
		node.setAttribute('target', '_blank')

		if (value.src) {
			node.setAttribute('class', 'link-image')
			const child = document.createElement('img')
			child.setAttribute('src', value.src)
			if (value.alt) {
				child.setAttribute('alt', value.alt)
			}
			if (value.style) {
				child.setAttribute('style', value.style)
			}
			if (value.width) {
				child.setAttribute('width', value.width)
			}

			node.appendChild(child)
		}

		return node
	}

	// get value of the node (for implement undo function)
	static value(node) {
		if (node.getAttribute('class') === 'link-image') {
			return {
				url: node.getAttribute('href'),
				src: node.firstChild.getAttribute('src'),
				alt: node.firstChild.getAttribute('alt'),
				style: node.firstChild.getAttribute('style'),
				width: node.firstChild.getAttribute('width'),
			}
		}
		// if (node.firstChild) {
		// 	return {
		// 		url: node.getAttribute('href'),
		// 		src: node.firstChild.getAttribute('src'),
		// 		alt: node.firstChild.getAttribute('alt'),
		// 		style: node.firstChild.getAttribute('style'),
		// 		width: node.firstChild.getAttribute('width')
		// 	}
		// } else {
		// 	return {
		// 		url: node.getAttribute('href')
		// 	}
		// }
	}
}

LinkImage.blotName = 'link-image'
LinkImage.tagName = 'a'
Quill.register(LinkImage)

// const BaseLinkFormat = Quill.import('formats/link')
// const LinkFormatAttributesList = [
// 	'href',
// 	'target'
// ]

// class LinkFormat extends BaseLinkFormat {
// 	static formats(domNode) {
// 		return LinkFormatAttributesList.reduce(function (formats, attribute) {
// 			if (domNode.hasAttribute(attribute)) {
// 				formats[attribute] = domNode.getAttribute(attribute)
// 			}
// 			return formats
// 		}, {})
// 	}

// 	format(name, value) {
// 		if (LinkFormatAttributesList.includes(name)) {
// 			if (value) {
// 				this.domNode.setAttribute(name, value)
// 			} else {
// 				this.domNode.removeAttribute(name)
// 			}
// 		} else {
// 			super.format(name, value)
// 		}
// 	}
// }

// Quill.register(LinkFormat, true)

// class LinkBlot extends BlockEmbed {
// 	static create(value) {
// 		const node = super.create()
// 		node.setAttribute('href', value.url)
// 		node.setAttribute('target', '_blank')
// 		return node
// 	}

// 	static value(node) {
// 		return {
// 			href: node.getAttribute('href'),
// 			target: node.getAttribute('target')
// 		}
// 	}
// }

// LinkBlot.blotName = 'link'
// LinkBlot.tagName = 'a'

// Quill.register(LinkBlot)

// class LinkBlot extends Inline {
// 	static create(value) {
// 		const node = super.create()
// 		// Sanitize url value if desired
// 		node.setAttribute('href', value)
// 		// Okay to set other non-format related attributes
// 		// These are invisible to Parchment so must be static
// 		node.setAttribute('target', '_blank')
// 		return node
// 	}

// 	static formats(node) {
// 		// We will only be called with a node already
// 		// determined to be a Link blot, so we do
// 		// not need to check ourselves
// 		return node.getAttribute('href')
// 	}
// }
// LinkBlot.blotName = 'link'
// LinkBlot.tagName = 'a'

// Quill.register(LinkBlot)

// /////////////////////////////////////

// const BaseLinkFormat = Quill.import('formats/link')
// const LinkFormatAttributesList = [
// 	'href',
// 	'target',
// 	'style'
// ]
// class LinkFormat extends BaseLinkFormat {
// 	static formats(domNode) {
// 		return LinkFormatAttributesList.reduce(function (formats, attribute) {
// 			if (domNode.hasAttribute(attribute)) {
// 				formats[attribute] = domNode.getAttribute(attribute)
// 			}
// 			return formats
// 		}, {})
// 	}

// 	format(name, value) {
// 		if (LinkFormatAttributesList.includes(name)) {
// 			if (value) {
// 				this.domNode.setAttribute(name, value)
// 			} else {
// 				this.domNode.removeAttribute(name)
// 			}
// 		} else {
// 			super.format(name, value)
// 		}
// 	}
// }

// Quill.register(LinkFormat, true)

Quill.register('modules/imageResize', ImageResize)

// quill html button
Quill.register('modules/htmlEditButton', htmlEditButton)

// Quill align inline
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

Vue.use(VueQuillEditor)

// https://codepen.io/DmitrySkripkin/pen/pVGmPP
