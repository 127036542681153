const warranty = '/v1/vehicles'
const listPackage = '/v1/mcn-service-pkgs'
const customer = '/v1/customers'
export default $axios => ({
	show(id, payload) {
		return $axios.get(`${warranty}/${id}`, payload)
	},

	update(id, idMcn, payload) {
		return $axios.post(`${warranty}/${id}/mcn-service/${idMcn}`, payload)
	},

	updateStoreType(id, payload) {
		return $axios.put(`${warranty}/${id}/change-type`, payload)
	},

	fetchStoreDetail(id) {
		return $axios.get(`${warranty}/${id}`)
	},

	fetchListPackageMcn() {
		return $axios.get(listPackage)
	},

	linkToCustomer(id, payload) {
		return $axios.put(`${customer}/${id}/vehicles`, null, payload)
	},

	addCustomer(payload) {
		return $axios.post(customer, null, {
			params: payload,
		})
	},
	updateCustomer(id, payload, headers) {
		return $axios.put(`${customer}/${id}`, payload, headers)
	},
	addVehicles(payload) {
		return $axios.post(warranty, payload)
	},

	updateVehicles(id, payload) {
		return $axios.post(`${warranty}/${id}`, payload)
	},

	addMcnPackageToVehicle(id, mcn_service_pkg_id, payload) {
		return $axios.put(
			`${warranty}/${id}/mcn-service-pkg/${mcn_service_pkg_id}`,
			null,
			{
				params: payload,
			}
		)
	},
})
