import Api from '~/helper/api'

const defaultFilter = {
	value: null,
	text: 'All',
}

const state = () => ({
	items: [],
	promoFilter: [],
	package: [defaultFilter],
	promoDetail: null,
	pagination: {
		current: 1,
		total: 0,
		perPage: 50,
	},
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},

	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},

	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current = payload.current
	},

	SET_PROMO__FILTER(state, payload) {
		state.promoFilter = payload
	},

	SET_PROMO_PACKAGE(state, payload) {
		if (payload) {
			const list = state.promoFilter.find(item => item.id === payload)

			if (list) {
				const format = list.packages.reduce((result, item, index) => {
					const total = {
						value: item.id,
						text: item.name,
					}
					result.push(total)
					return result
				}, [])

				state.package = [defaultFilter, ...format]
			} else {
				state.package = [defaultFilter]
			}
		} else {
			state.package = [defaultFilter]
		}
	},

	SET_PROMO_STATUS_AFTER_UPDATE(state, payload) {
		state.items[
			state.items.findIndex(item => item.id === payload.id)
		].status = payload.data.status
	},

	SET_PROMO_DETAIL(state, payload) {
		state.promoDetail = payload
	},
}

const getters = {
	getItems: state => state.items,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getPromoTypeFilter: state => {
		const list = state.promoFilter.reduce((result, item, index) => {
			const total = {
				value: item.id,
				text: item.name,
			}
			result.push(total)
			return result
		}, [])

		return [defaultFilter, ...list]
	},
	getPromoPackage: state => state.package,
	getPromoDetail: state => state.promoDetail,
}

const actions = {
	/**
	 * @description fetch list promo-product
	 * @param {Object} payload
	 */
	async fetch({ commit, dispatch }, payload) {
		try {
			const res = await this.$axios.get(Api('promo-product'), {
				params: {
					...payload.params,
				},
			})

			if (res.status === 200) {
				const data = res.data.data
				commit('SET_ITEMS', data)
				commit('SET_PAGINATION', {
					current: res.data.current,
					total: res.data.total,
					perPage: 1,
				})
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},

	/**
	 * @description Fetch promo-type and package for each promo-type
	 */
	async fetchPromoType({ commit, dispatch }, payload) {
		try {
			const res = await this.$axios.get(Api('promo-product-type-filter'))

			if (res.status === 200) {
				commit('SET_PROMO__FILTER', res.data.data)
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},

	/**
	 * @description Update promo status
	 * @param {Object} payload
	 */
	async updatePromoStatus({ commit, dispatch }, payload) {
		try {
			const { id, status } = payload
			const res = await this.$axios.put(
				Api('promo-product-update-status', id),
				status
			)

			if (res.status === 200 && res.data.data) {
				commit('SET_PROMO_STATUS_AFTER_UPDATE', {
					id: id,
					data: res.data.data,
				})
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},

	/**
	 * @description Get detail promo
	 * @param {Number} payload
	 */
	async showPromoDetail({ commit, dispatch }, payload) {
		try {
			const res = await this.$axios.get(
				Api('promo-product-promo-detail', payload)
			)
			if (res.status === 200) {
				commit('SET_PROMO_DETAIL', res.data.data)
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},
}

export default {
	state,
	actions,
	getters,
	mutations,
}
