const getDefaultState = () => {
	return {
		noticeList: [],
		loading: false,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getNoticeList: state => state.noticeList,
	getLoading: state => state.loading,
}

const actions = {
	async fetch({ commit, rootGetters }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get('/notices', {
				cancelToken: payload.axiosSource
					? payload.axiosSource.token
					: null,
			})
			if (res.status === 200) {
				commit('setNoticeList', res.data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setNoticeList(state, list) {
		state.noticeList = list
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
