const resource = '/leads'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	show(id) {
		return $axios.get(`${resource}/${id}`)
	},
	updateStatus(payload) {
		return $axios.put(`${resource}/status/update-multi`, payload)
	},
})
