import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		loading: false,
		error: null,
		faqsCategory: [],
		pagination: {
			current: 1,
			total: 0,
			perPage: 50,
		},
		faqsCategoryLv1: [],
		category: null,
		showCategory: null,
		faqsCategoryLv2: [],
		faqs: [],
	}
}

const state = () => getDefaultState()

const getters = {
	getLoading: state => state.loading,
	getError: state => state.error,
	getFaqsCategory: state => state.faqsCategory,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getFaqsCategoryLv1: state => state.faqsCategoryLv1,
	getShowCategory: state => state.showCategory,
	getFaqsCategoryLv2: state => state.faqsCategoryLv2,
	getCategory: state => state.category,
	getFaqs: state => state.faqs,
}

const actions = {
	async fetchFaq({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('faqs-fetch'), {
				params: { ...payload.params.query },
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setFaq', data)
				commit('setPaginayion', {
					current: res.data.current,
					total: res.data.total,
					perPage: 9,
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async fetchFaqsCategory({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('faqs-category-fetch'), {
				params: { ...payload.params },
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setFaqCategory', data)
				commit('setPaginayion', {
					current: res.data.current,
					total: res.data.total,
					perPage: 50,
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async createCategory({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(
				Api('faqs-category-create'),
				payload
			)
			if (res.data.result_code !== 1) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: res.data.result,
					},
					{
						root: true,
					}
				)
			} else {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: 'Create FAQ category successful!',
						positiveCb: () => {
							dispatch('alert/setAlert', { dialog: false })
							this.$router.replace({ path: '/faqs/category' })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async deleteFaqs(_, payload) {
		try {
			const res = await this.$axios.delete(Api('faqs-delete', payload))
			return res
		} catch (err) {
			return err
		}
	},
	async deleteFaqsCategory(_, payload) {
		try {
			const res = await this.$axios.delete(
				Api('faqs-category-delete', payload)
			)
			return res
		} catch (error) {
			return error
		}
	},
	async updateStatus(_, payload) {
		try {
			const res = await this.$axios.put(
				Api('faqs-status-update', payload.params.id),
				{ status: payload.params.status }
			)
			return res
		} catch (err) {
			return err
		}
	},
	async fetchFaqCategoryLv1({ commit }) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('faqs-category-fetch-lv1'))
			if (res.status === 200) {
				const data = res.data.data
				commit('setFetchCategoryLv1', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async fetchFaqCategoryLv2({ commit }) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('faqs-category-fetch-lv2'))
			if (res.status === 200) {
				const data = res.data.data
				commit('setFetchCategoryLv2', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async showFaqCategory({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(
				Api('faqs-category-show', payload)
			)
			if (res.data.result_code === 1) {
				commit('setShowCategory', res.data.data)
			} else {
				dispatch('alert/setAlert', {
					dialog: true,
					type: 'error',
					messages: res.data.result,
				})
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async updateFaqCategory({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(
				Api('faqs-category-update', payload.id),
				payload.formData
			)
			if (res.data.result_code !== 1) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: res.data.result,
					},
					{
						root: true,
					}
				)
			} else {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: 'Update FAQ category successful!',
						positiveCb: () => {
							dispatch('alert/setAlert', { dialog: false })
							this.$router.replace({ path: '/faqs/category' })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	setLoading(state, payload) {
		state.loading = payload
	},
	setError(state, payload) {
		state.error = { ...payload }
	},
	setFetchCategoryLv1(state, payload) {
		state.faqsCategoryLv1 = payload
	},
	setFetchCategoryLv2(state, payload) {
		state.faqsCategoryLv2 = payload
	},
	setFaqCategory(state, payload) {
		state.faqsCategory = payload
	},
	setCurrentPage(state, payload) {
		state.pagination.current = payload.current
	},
	setPaginayion(state, payload) {
		state.pagination = { ...payload }
	},
	setCategory(state, payload) {
		state.category = { ...payload }
	},
	setShowCategory(state, payload) {
		state.showCategory = payload
	},
	setFaq(state, payload) {
		state.faqs = payload
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
