const state = () => ({
	dialog: false,
	type: null, // Warning OR Error
	title: null,
	messages: null,
	overlay: false,
	fbAction: null,
})

const getters = {
	getDialog: state => state.dialog,
	getType: state => state.type,
	getMessages: state => state.messages,
	getOverlay: state => state.overlay,
	getFbAction: state => state.fbAction,
}

const mutations = {
	SET_ALERT(state, payload) {
		const { dialog, type, messages, fbAction } = payload
		state.dialog = dialog || false
		state.type = type || null
		state.messages = messages || null
		state.fbAction = fbAction || null
	},

	SET_OVERLAY(state, payload) {
		state.overlay = payload
	},
}

const actions = {
	/**
	 * set alert open or close
	 * with diffrent type, message and onClick button
	 * @param {boolean} dialog on/off dialog
	 * @param {string} type with 3 type confirm/error/success
	 * @param {string} title dialog's title
	 * @param {string} message dialog's body message
	 * @param {function} positiveCb dialog's positive callback
	 * @param {function} negativeCb dialog's negative callback
	 */
	setAlert({ commit }, payload) {
		commit('SET_ALERT', payload)
	},

	setOverlay({ commit }, payload) {
		commit('SET_OVERLAY', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
