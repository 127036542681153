import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		stores: [],
		query: {
			type: null,
			keyword: null,
			sort_by: null,
			order_by: null,
			count: null,
			page: null,
		},
		filter: {
			within_day: null,
			range_date: null,
			start_date: null,
			end_date: null,
			location: null,
			display: null,
			own_products: null,
			sold_products: null,
			purchased_products: null,
			followers: null,
		},
		pagination: {
			current: 1,
			total: 0,
			perPage: 50,
		},
		loading: false,
		totalValue: 0,
		storeInfo: null,
	}
}

const state = () => getDefaultState()

const getters = {
	getStores: state => state.stores,
	getTotalValue: state => state.totalValue,
	getLoading: state => state.loading,
	getFilter: state => state.filter,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getStoreInfo: state => state.storeInfo,
	getStationStore: state =>
		state.stores.filter(state => state.is_station === 2),
}

const actions = {
	async fetch({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('store-fetch'), {
				params: {
					...payload.params,
					count: 50,
				},
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setStores', data)
				commit('setPagination', {
					current: res.data.current,
					total: res.data.total,
					perPage: 50,
				})
			}
		} catch (err) {
			if (
				err &&
				err.message === 'Operation canceled by the Route change.'
			) {
				console.log('Operation canceled by the Route change.')
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('setLoading', false)
		}
	},
	async search({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('store-search'), {
				params: {
					...payload.params,
					count: 50,
				},
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setStores', data)
			}
		} catch (err) {
			if (
				err &&
				err.message === 'Operation canceled by the Route change.'
			) {
				console.log('Operation canceled by the Route change.')
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('setLoading', false)
		}
	},
	async fetchTotalAmount({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const resTotal = await this.$axios.get(
				Api('store-total-sale-amount'),
				{
					params: {
						...payload.params,
						count: 50,
					},
				}
			)
			if (resTotal.status === 200) {
				const data = resTotal.data.data
				commit('setTotalSaleAmount', data)
			}
		} catch (err) {
			if (
				err &&
				err.message === 'Operation canceled by the Route change.'
			) {
				console.log('Operation canceled by the Route change.')
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: err.data ? err.data.result : err,
					},
					{ root: true }
				)
			}
		} finally {
			commit('setLoading', false)
		}
	},
	async fetchById({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('store-fetch-by-id'), {
				params: {
					...payload.params,
					// count: 50
				},
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setStores', data)
				commit('setPagination', {
					current: res.data.current,
					total: res.data.total,
					perPage: 50,
				})
			}
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async update({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(
				Api('store-update', payload.storeId),
				{
					...payload.body,
				}
			)
			commit('setStoresItem', {
				data: res.data.data,
				index: payload.index,
			})
			console.log(res)
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async updateLoanPermis({ commit, dispatch }, payload) {
		const { id, type } = payload
		commit('setLoading', true)

		try {
			await this.$axios.put(Api('store-update-loan', id), {
				is_loanable: type,
			})
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async fetchStoreInfo({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(
				Api('fetch-store-detail', payload.store_id)
			)
			if (res.status === 200) {
				const data = res.data.data
				commit('setStoreInfo', data)
			}
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async updateStoreGeneralInfo({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			await this.$axios.put(Api('store-update', payload.store_id), {
				...payload.body,
			})
			const res = await this.$axios.get(
				Api('fetch-store-detail', payload.store_id)
			)

			if (res.status !== 200 || res.data.result_code !== 1) {
				throw res
			}
			const data = res.data.data
			commit('setStoreInfo', data)
			dispatch(
				'qMessage/setQMessage',
				{
					body: 'Update successfully',
					qMessage: true,
				},
				{ root: true }
			)
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
	async updateStoreIdentityInfo({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(
				Api('store-identity-update', payload.store_id),
				payload.body
			)

			if (res.status !== 200 || res.data.result_code !== 1) {
				throw res
			}
			const data = res.data.data
			commit('setStoreInfo', data)
			dispatch(
				'qMessage/setQMessage',
				{
					body: 'Update successfully',
					qMessage: true,
				},
				{ root: true }
			)
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
			// commit('setStoreInfo', state.storeInfo)
		} finally {
			commit('setLoading', false)
		}
	},
	async changeStoreType({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(
				Api('store-type-update', payload.store_id),
				payload.body
			)

			if (res.status === 200) {
				const data = res.data.data
				commit('setStoreInfo', data)
			}
			dispatch(
				'qMessage/setQMessage',
				{
					body: 'Update successfully',
					qMessage: true,
				},
				{ root: true }
			)
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setStores(state, data) {
		state.stores = [...data]
	},
	setStoresItem(state, payload) {
		Object.assign(state.stores[payload.index], payload.data)
	},
	setLoading(state, loading) {
		state.loading = loading
	},
	setFilter(state, data) {
		state.filter = { ...data }
	},
	setFilterElement(state, data) {
		state.filter = {
			...state.filter,
			...data,
		}
	},
	setFilter2(state, data) {
		state.filter2 = [...data]
	},
	addFilter2(state, data) {
		state.filter2.push(data)
	},
	setPagination(state, payload) {
		state.pagination = { ...payload }
	},
	setCurrentPage(state, payload) {
		state.pagination.current = payload.current
	},
	setStoreInfo(state, data) {
		state.storeInfo = { ...data }
	},
	setTotalSaleAmount(state, payload) {
		state.totalValue = payload
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
