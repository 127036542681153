const api = {
	// Auth--------------------------
	'auth-login': '/auth/login',
	'auth-logout': '/auth/logout',
	'auth-change-password': '/users/change_password',
	// Managers----------------------
	'manager-fetch': '/managers',
	// get /managers/:id
	'manager-create': '/managers',
	// post change password /managers/:id/change-role
	// post change password /managers/:id/change-password
	// post change password /managers/:id/reset-password
	// post change password /managers/:id/confirm
	// put /managers/:id
	// delete /managers/:id
	// Roles-------------------------
	'role-fetch': '/roles',
	'role-post': '/roles',
	'role-update': '/roles/:id',
	'role-delete': '/roles/:id',
	// Permissions--------------------
	'permission-fetch': '/permissions',
	// Products------------------------
	'product-fetch': '/products',
	'product-fetch-v2': '/v2/products',
	'product-create': '/v2/products',
	'product-update': '/products/:id',
	'product-update-v2': '/v2/products/:id',
	'product-update-v3': '/v3/products/:id',
	'product-approve': '/products/:id/confirm-sales',
	'product-delete': '/products/:id',
	'product-restore': 'products/:id/restore',
	'product-detail': '/v2/products/:id',

	'fetch-models': '/v2/brands/:id/models',
	// fetch /events
	// fetch /events?page=1
	// get /events/:id
	// put /events/:id
	// delete /events/:id
	// News--------------------------
	'news-fetch': '/news',
	'news-show': '/news/:id',
	'news-create': '/news',
	'news-update': '/news/:id',
	// fetch /news?page=1
	// get /news/:id
	// delete /news/:id
	// Events------------------------
	// post /events
	// fetch /events
	// fetch /events?page=1
	// get /events/:id
	// put /events/:id
	// delete /events/:id
	// Stores-------------------------
	'store-fetch': '/v2/stores',
	'store-search': '/v2/stores/search',
	'store-total-sale-amount': 'v2/stores/total-sale-amount',
	'store-fetch-by-id': '/search/store',
	'store-update': '/v2/stores/:id',
	'store-update-loan': '/v2/stores/:id',
	'fetch-location': '/locations/cities',
	'store-type-update': '/v2/stores/:id/change-type',
	'fetch-store-detail': '/v2/stores/:id',
	'store-identity-update': '/v2/stores/:id/update-identity',
	// Admin Users-------------------------
	// fetch+pagination+filter /users?page=1?filter[location]=hcm&filter[login-count]=50
	'users-show': '/users/:id',
	'users-get-info': '/me/account',
	// post /users/:id/reset-password
	// post /users/:id/block
	// delete /users/:id

	// transaction V2
	'fetch-transaction-detail-v2': '/v2/transactions/:id',

	// Loan Management
	'loan-fetch': '/loans',
	'loan-detail': '/loans/:id',
	'loan-update-bank-account': '/loans/:id',
	'loan-get-buyer': '/loans/:id/buyer',
	'loan-update-status': '/loans/:id',
	'loan-get-history': '/loans/:id/history',
	'loan-get-product': '/products/:id',
	'loan-get-paperwork': '/paperworks/:id',
	'loan-confirm-paperwork': '/paperworks/:id/confirm',
	'loan-disbursement-info': '/loans/:id/disbursement',
	'loan-disbursement-notification': '/loans/:id/loan-disbursed',

	// Banners Management
	'banners-fetch': '/banners',
	'banner-detail': '/banners/:id',
	'banner-sort': 'banners/sort',

	// Event
	'event-fetch': '/events',
	'event-create': '/events',
	'event-update': '/events/:id',
	'event-delete': '/events/:id',
	'event-fetch-id': '/events/:id',
	'event-check-title': '/events/check-title-seo',
	'event-update-status': '/events/status/:id',

	// User managers
	'user-fetch': '/users',
	'user-create': '/users',
	'user-update': '/users/:id',

	// Promo Product
	'promo-product': '/commercial/product-list',
	'promo-product-type-filter': '/commercial/list-detail-package',
	'promo-product-update-status': '/commercial/change-status/:id',
	'promo-product-promo-detail': '/commercial/product-list/detail/:id',

	// Promo-Setting
	'promo-list': '/commercial/setting',
	'promo-update-status': '/commercial/detail/:id',
	'promo-update-price': '/commercial/package',
	// FAQs
	'faqs-fetch': '/faqs',
	'faqs-delete': '/faqs/:id',
	'faqs-category-create': '/category-faqs',
	'faqs-category-fetch': '/category-faqs',
	'faqs-category-show': '/category-faqs/detail/:id',
	'faqs-category-delete': '/category-faqs/:id',
	'faqs-category-fetch-lv1': '/category-faqs/level?level=1',
	'faqs-category-fetch-lv2': '/category-faqs/level?level=2',
	'faqs-category-update': '/category-faqs/:id',
	'faqs-status-update': '/faqs/status/:id',

	// Get Platforms
	'fetch-platforms': '/platform',
	// Ads plan
	'topup-fetch': '/topup-settings',
	'edit-topup-settings': '/topup-settings/batch/update',
	'product-ads-fetch': '/campaign-settings',
	'campaign-settings-update': '/campaign-settings/:id/with-budgets',
	'fetch-ads-account': '/balances',
	'fetch-account-transactions': '/balances/:id/transactions',
	'fetch-ads-product': '/campaigns',
	'update-ads-status': '/campaigns/:id/status',
	'fetch-ads-detail': '/campaigns/:id',
	'fetch-acc-detail': '/balances/:id',
	'fetch-acc-campaigns': '/balances/:id/campaigns',
	'fetch-statistics': '/statistics',
	'fetch-top-stores': '/statistics/top/wallet',
	'fetch-statistics-overview': '/statistics/overview',
	'edit-transaction-note': '/transactions/:id/note',
	'edit-transaction-status': '/transactions/:id/status',
	'fetch-cash-orders': '/cash/cash-orders',
	'fetch-alepay-orders': 'alepays',
	'create-cash-order': '/cash/create-order',
	'update-note': 'cash/update-note/:id',
	'fetch-all-users': 'users/search/user-admin',
	'change-cash-status': '/cash/change/:id/status',
	'fetch-product-detail': '/v2/products/:id/detail',
	'update-station-product': '/v2/products/station/update/:id',
	'fetch-vehicles-detail': '/v1/vehicles/:id',
	'fetch-machine-sevices-package': '/v1/mcn-service-pkgs',
	'update-machine-item-to-machine-service': '/v1/vehicles/:id/mcn-service/2',
	'fetch-machine-service-history': '/v1/vehicles/:id/mcn-service-histories',
	'fetch-vehicles-list': '/v1/vehicles',
	'update-vehicle-service': '/v1/vehicles/:id/mcn-service/2',
	'search-customer': '/v1/customers',
	'link-to-customer': '/v1/customers/:id/vehicles',
	'search-user': '/v2/users/search',
}

const getApi = (key, id) => {
	// no key -> return undefined
	if (!key) {
		return
	}

	if (api[key]) {
		return api[key].replace(':id', id || '')
	}
}

export default getApi
