import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		loading: false,
		locations: [],
	}
}

const state = () => getDefaultState()

const getters = {
	getLocations: state => state.locations,
}

const actions = {
	async fetchLocation({ commit, dispatch }) {
		commit('setLoading', true)
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('fetch-location'))
			if (res.status === 200) {
				const data = res.data.data
				commit('setLocations', data)
			}
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	setLocations(state, data) {
		state.locations = [...data]
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
