var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',_vm._b({staticClass:"sidebar",attrs:{"color":_vm.color,"width":"220","mobile-breakpoint":"960","disable-resize-watcher":"","fixed":"","app":"","dark":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"sidebar__logo"},[_c('img',{attrs:{"src":"/images/okxe-logo-v2.svg","height":"60","width":"60"}}),_vm._v(" "),_c('span',{staticClass:"sidebar__logo-okxe"},[_vm._v("OKXE")]),_vm._v(" "),_c('span',{staticClass:"sidebar__logo-admin"},[_vm._v("Admin")])]),_vm._v(" "),_c('user-block')]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_vm._v(" "),_c('div',{staticClass:"sidebar__nav"},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(
						item.child &&
							_vm.userInfo.role &&
							_vm.userInfo.role.length > 0 &&
							_vm.showSidebar(item.permissions)
					)?_c('v-list-group',{attrs:{"active-class":"group-menu-active","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((item.child),function(child,i){return _c('v-list-item',{directives:[{name:"permission",rawName:"v-permission",value:({
							permission: child.permission,
							userInfo: _vm.userInfo,
						}),expression:"{\n\t\t\t\t\t\t\tpermission: child.permission,\n\t\t\t\t\t\t\tuserInfo: userInfo,\n\t\t\t\t\t\t}"}],key:i,attrs:{"to":child.to,"router":"","exact":""}},[_c('v-list-item-title',{staticClass:"px-o-10",domProps:{"textContent":_vm._s(child.title)}})],1)})],2):_c('v-list-item',{directives:[{name:"permission",rawName:"v-permission",value:({
						permission: item.permission,
						userInfo: _vm.userInfo,
					}),expression:"{\n\t\t\t\t\t\tpermission: item.permission,\n\t\t\t\t\t\tuserInfo: userInfo,\n\t\t\t\t\t}"}],attrs:{"nuxt":"","to":item.to,"router":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)],1)}),0)],1),_vm._v(" "),_c('div',{staticClass:"sidebar__footer"},[_c('div',{staticClass:"sidebar__footer-brand"},[_vm._v("\n\t\t\tOKXE.vn\n\t\t")]),_vm._v(" "),_c('div',{staticClass:"sidebar__footer-operator"},[_c('span',[_vm._v("대표자 : Wooseok Kim")]),_c('br'),_vm._v(" "),_c('span',[_vm._v("사이트 담당 관리자 : Trang Vu")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }