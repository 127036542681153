const getDefaultState = () => {
	return {
		drawer: false,
		previousRoute: null,
		pages: {
			products: {
				table: [
					{
						id: 1,
						text: 'ID',
						checked: true,
						value: 'id',
						disabled: true,
					},
					{
						id: 2,
						text: 'Picture',
						checked: true,
						value: 'default_image',
					},
					{
						id: 3,
						text: 'Subject',
						checked: true,
						value: 'store_name',
					},
					{
						id: 4,
						text: 'Phone',
						checked: true,
						value: 'phone_number',
					},
					{
						id: 5,
						text: 'Product Type',
						checked: true,
						value: 'product_type',
					},
					{ id: 6, text: 'Make', checked: true, value: 'brand_name' },
					{
						id: 7,
						text: 'Model(Trim)',
						checked: true,
						value: 'model_name',
					},
					{
						id: 8,
						text: 'Category',
						checked: true,
						value: 'category',
					},
					{
						id: 9,
						text: 'Location',
						checked: true,
						value: 'location',
					},
					{
						id: 10,
						text: 'Number Plate',
						checked: true,
						value: 'license_plate',
					},
					{
						id: 11,
						text: 'Release Year',
						checked: true,
						value: 'release_year',
					},
					{
						id: 12,
						text: 'Used Distance',
						checked: true,
						value: 'used_distance',
					},
					{ id: 13, text: 'Price', checked: true, value: 'price' },
					{
						id: 14,
						text: 'Inspection',
						checked: true,
						value: 'inspection',
					},
					{
						id: 15,
						text: 'Inspection Date',
						checked: true,
						value: 'inspection_date',
					},
					{
						id: 16,
						text: 'Registered Date',
						checked: true,
						value: 'registered_date',
					},
					{
						id: 17,
						text: 'Created By',
						checked: true,
						value: 'created_by',
					},
					{
						id: 18,
						text: 'Last updated at',
						checked: true,
						value: 'status_changed_date',
					},
					{
						id: 19,
						text: 'Status updated at',
						checked: true,
						value: 'status_update_at',
					},
					{
						id: 20,
						text: 'Status updated by',
						checked: true,
						value: 'status_update_by',
					},
					{
						id: 21,
						text: 'Selling at Station',
						checked: true,
						value: 'selling_at_station',
					},
					{
						id: 22,
						text: 'Quality item',
						checked: true,
						value: 'quality_item',
					},
					{
						id: 23,
						text: 'Status',
						checked: true,
						value: 'product_status',
					},
					{
						id: 24,
						text: 'Blocked Reason',
						checked: true,
						value: 'blocked_reason',
					},
					{
						id: 25,
						text: 'Menu',
						checked: true,
						value: 'icon-action',
						disabled: true,
					},
				],
			},
		},
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getDrawer: state => state.drawer,
	getPreviousRoute: state => state.previousRoute,
	getSettings: state => state.settings,
	getProductTableSetting: state => state.pages.products.table,
}

const actions = {
	toggleDrawer({ commit, state }) {
		commit('setDrawer', !state.drawer)
	},

	saveProductsTableSetting({ commit }, payload) {
		commit('setProductsTableSetting', payload)
	},

	reset({ commit }) {
		commit('resetState')
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setDrawer(state, value) {
		state.drawer = value
	},
	setPreviousRoute(state, data) {
		state.previousRoute = { ...data }
	},

	setProductsTableSetting(state, data) {
		state.pages.products.table = [...data]
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
