const getDefaultState = () => {
	return {
		drawer: true,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getDrawer: state => state.drawer,
}

const actions = {
	toggleDrawer({ commit, state }) {
		commit('setDrawer', !state.drawer)
	},
}

const mutations = {
	resetState() {
		Object.assign(state, getDefaultState())
	},
	setDrawer(state, value) {
		state.drawer = value
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
