const resource = '/partners'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	show(id) {
		return $axios.get(`${resource}/${id}`)
	},
	create(payload) {
		return $axios.post(`${resource}`, payload)
	},
	edit(id, payload) {
		return $axios.post(`${resource}/${id}`, payload)
	},
	updateStatus(id, payload) {
		return $axios.put(`${resource}/active/${id}`, payload)
	},
})
