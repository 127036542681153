<template>
	<v-dialog v-model="dialog" :persistent="persistent" max-width="438">
		<component
			:is="type"
			:title="title"
			:message="message"
			:positive-text="positiveText"
			:positive-cb="positiveButton"
			:negative-text="negativeText"
			:negative-cb="negativeButton"
		/>
	</v-dialog>
</template>

<script>
import AlertConfirm from '~/components/alert/AlertConfirm'
import AlertError from '~/components/alert/AlertError'
import AlertSuccess from '~/components/alert/AlertSuccess'
import AlertSuccessOption from '~/components/alert/AlertSuccessOption.vue'

// For testing purpose to test reuse component and customizability of dynamic component
// NOT GOOD
export default {
	components: {
		AlertConfirm,
		AlertError,
		AlertSuccess,
		AlertSuccessOption,
	},

	data() {
		return {
			component: '',
		}
	},

	computed: {
		// v-model dialog to turn on/off
		dialog: {
			get() {
				return this.$store.getters['alert/getDialog']
			},
			set() {
				this.$store.commit('alert/RESET_STATE')
			},
		},
		/**
		 * type of dialog
		 */
		type() {
			const alertType = this.$store.getters['alert/getType']

			if (alertType === 'success') {
				return 'AlertSuccess'
			}

			if (alertType === 'error') {
				return 'AlertError'
			}

			if (alertType === 'confirm') {
				return 'AlertConfirm'
			}

			if (alertType === 'option') {
				return AlertSuccessOption
			}

			return 'AlertSuccess'
		},
		// body message of dialog
		title() {
			return this.$store.getters['alert/getTitle']
		},
		// body message of dialog
		message() {
			return this.$store.getters['alert/getMessage']
		},
		persistent() {
			return this.$store.getters['alert/getPersistent']
		},
		positiveText() {
			return this.$store.getters['alert/getPositiveText']
		},
		// on positive button click
		positiveButton() {
			return this.$store.getters['alert/getPositiveCb']
		},
		negativeText() {
			return this.$store.getters['alert/getNegativeText']
		},
		// on negative button click
		negativeButton() {
			return this.$store.getters['alert/getNegativeCb']
		},
	},
}
</script>
