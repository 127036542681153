const getDefaultState = () => {
	return {
		locations: [
			{
				value: null,
				text: 'Any Location',
				sort_num: '1',
				checked: false,
				tag: ['any'],
			},
			{
				value: '1',
				text: 'Hà Nội',
				sort_num: '1',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '2',
				text: 'Hồ Chí Minh',
				sort_num: '2',
				checked: false,
				tag: ['southern', 'dong_nam_bo'],
			},
			{
				value: '3',
				text: 'Hải Phòng',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '4',
				text: 'Đà Nẵng',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '5',
				text: 'Cần Thơ',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '6',
				text: 'An Giang',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '7',
				text: 'Bà Rịa - Vũng Tàu',
				sort_num: '99',
				tag: ['southern', 'dong_nam_bo'],
				checked: false,
			},
			{
				value: '8',
				text: 'Bắc Giang',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '9',
				text: 'Bắc Kạn',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '10',
				text: 'Bạc Liêu',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '11',
				text: 'Bắc Ninh',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '12',
				text: 'Bến Tre',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '13',
				text: 'Bình Định',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '14',
				text: 'Bình Dương',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'dong_nam_bo'],
			},
			{
				value: '15',
				text: 'Bình Phước',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'dong_nam_bo'],
			},
			{
				value: '16',
				text: 'Bình Thuận',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '17',
				text: 'Cà Mau',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '18',
				text: 'Cao Bằng',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '19',
				text: 'Đắk Lắk',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'tay_nguyen'],
			},
			{
				value: '20',
				text: 'Đắk Nông',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'tay_nguyen'],
			},
			{
				value: '21',
				text: 'Điện Biên',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '22',
				text: 'Đồng Nai',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'dong_nam_bo'],
			},
			{
				value: '23',
				text: 'Đồng Tháp',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '24',
				text: 'Gia Lai',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'tay_nguyen'],
			},
			{
				value: '25',
				text: 'Hà Giang',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '26',
				text: 'Hà Nam',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '27',
				text: 'Hà Tĩnh',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '28',
				text: 'Hải Dương',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '29',
				text: 'Hậu Giang',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '30',
				text: 'Hòa Bình',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '31',
				text: 'Hưng Yên',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '32',
				text: 'Khánh Hòa',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '33',
				text: 'Kiên Giang',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '34',
				text: 'Kon Tum',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'tay_nguyen'],
			},
			{
				value: '35',
				text: 'Lai Châu',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '36',
				text: 'Lâm Đồng',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'tay_nguyen'],
			},
			{
				value: '37',
				text: 'Lạng Sơn',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '38',
				text: 'Lào Cai',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '39',
				text: 'Long An',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '40',
				text: 'Nam Định',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '41',
				text: 'Nghệ An',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '42',
				text: 'Ninh Bình',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '43',
				text: 'Ninh Thuận',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '44',
				text: 'Phú Thọ',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '45',
				text: 'Quảng Bình',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '46',
				text: 'Quảng Nam',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '47',
				text: 'Quảng Ngãi',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
			{
				value: '48',
				text: 'Quảng Ninh',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '49',
				text: 'Quảng Trị',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '50',
				text: 'Sóc Trăng',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '51',
				text: 'Sơn La',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '52',
				text: 'Tây Ninh',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'dong_nam_bo'],
			},
			{
				value: '53',
				text: 'Thái Bình',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '54',
				text: 'Thái Nguyên',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '55',
				text: 'Thanh Hóa',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '56',
				text: 'Thừa Thiên Huế',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'bac_trung_bo'],
			},
			{
				value: '57',
				text: 'Tiền Giang',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '58',
				text: 'Trà Vinh',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '59',
				text: 'Tuyên Quang',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'dong_bac_bo'],
			},
			{
				value: '60',
				text: 'Vĩnh Long',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'db_song_cuu_long'],
			},
			{
				value: '61',
				text: 'Vĩnh Phúc',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'db_song_hong'],
			},
			{
				value: '62',
				text: 'Yên Bái',
				sort_num: '99',
				checked: false,
				tag: ['northern', 'tay_bac_bo'],
			},
			{
				value: '63',
				text: 'Phú Yên',
				sort_num: '99',
				checked: false,
				tag: ['southern', 'nam_trung_bo'],
			},
		],
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getLocations: state => state.locations,
	getIdSouthern: state =>
		state.locations
			.filter(x => x.tag.includes('southern'))
			.map(id => id.value)
			.join(','),
	getIdNorthern: state =>
		state.locations
			.filter(x => x.tag.includes('northern'))
			.map(id => id.value)
			.join(','),
}

const actions = {
	applyLocationFilter({ commit }, payload) {
		commit('setLocationFilter', payload)
	},
}

const mutations = {
	resetLocation(state) {
		Object.assign(state, getDefaultState())
	},

	setLocationFilter(state, data) {
		state.locations = [...data]
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
