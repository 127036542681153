<template>
	<div>
		<template v-if="type == 'success'">
			<v-icon large color="#00bcc3">
				mdi-check-circle-outline
			</v-icon>
			Successful
		</template>
		<template v-else-if="type == 'warning'">
			<v-icon large color="yellow">
				mdi-alert-outline
			</v-icon>
			Warning
		</template>
		<template v-else>
			<v-icon large color="red">
				mdi-close-circle-outline
			</v-icon>
			Error
		</template>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
	},
}
</script>
