<template>
	<!-- <v-dialog
		v-model="dialog"
		persistent
		max-width="400"
	>
		<component
			:is="type"
			:title="title"
			:message="message"
			:positive-text="positiveText"
			:positive-cb="positiveButton"
			:negative-text="negativeText"
			:negative-cb="negativeButton"
		/>
	</v-dialog> -->
	<v-snackbar
		v-model="qMessage"
		:timeout="timeout"
		min-width="275px"
		:color="qMessageType.color"
		top
		centered
		class="q-message"
	>
		<v-icon color="#ffffff" size="24px">
			{{ qMessageType.icon }}
		</v-icon>
		<span class="ml-4" :class="bodyMessage === 'Deleted' ? 'aaaa' : ''">
			{{ bodyMessage }}
		</span>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {}
	},
	computed: {
		// success/inform/warn/error
		qMessage: {
			get() {
				return this.$store.getters['qMessage/getQMessage']
			},
			set(value) {
				this.$store.commit('qMessage/SET_CLOSE_QUICK_MESSAGE', value)
			},
		},
		type() {
			return this.$store.getters['qMessage/getType']
		},
		// body message of snake
		bodyMessage() {
			let bodyMessage = this.$store.getters['qMessage/getBody']
			if (bodyMessage) {
				return bodyMessage
			}
			switch (this.type) {
				case 'success':
					bodyMessage = 'Successful!'
					break
				case 'info':
					bodyMessage = 'Info!'
					break
				case 'warn':
					bodyMessage = 'Warning!'
					break
				case 'error':
					bodyMessage = 'Error!'
					break
				case 'delete':
					bodyMessage = 'Deleted'
					break
				default:
					bodyMessage = 'Successful!'
					break
			}
			return bodyMessage
		},
		closeBtn() {
			return this.$store.getters['qMessage/getCloseBtn']
		},
		timeout() {
			return this.$store.getters['qMessage/getTimeout']
		},
		qMessageType() {
			const qMessageType = {
				color: '',
				icon: '',
			}
			switch (this.type) {
				case 'success':
					qMessageType.color = '#4CAF50'
					qMessageType.icon = 'check_circle'
					break
				case 'info':
					qMessageType.color = '#00BCC3'
					qMessageType.icon = 'info'
					break
				case 'warn':
					qMessageType.color = '#FB8C00'
					qMessageType.icon = 'priority_high'
					break
				case 'error':
					qMessageType.color = '#FF5252'
					qMessageType.icon = 'warning'
					break
				case 'delete':
					qMessageType.color = '#4CAF50'
					qMessageType.icon = 'check_circle'
					break
				default:
					qMessageType.color = '#00D664'
					qMessageType.icon = 'check_circle'
					break
			}
			return qMessageType
		},
	},
}
</script>
<style lang="scss">
.q-message {
	top: 69px;
	.v-snack__content {
		justify-content: flex-start;
	}
}
</style>
