import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc)

const dateLib = {
	/**
	 * ham dung de format ngay thang theo dinh dang format truyen vao
	 *
	 * @param {string} date inout date
	 * @param {string} format format string type
	 * @param {string} emptyString string text if u want to custom the null date
	 * @return {string} return string date formarted
	 */
	format(date, format = 'DD-MM-YYYY', emptyString = '') {
		// check date is exist
		if (date) {
			return dayjs(date).format(format)
		}
		return emptyString || ''
	},

	diff(start, end, type) {
		if (start && end) {
			const startTime = dayjs(start)
			const endTime = dayjs(end)
			return endTime.diff(startTime, type)
		} else {
			return null
		}
	},

	initDate(date, format) {
		return dayjs(date, format)
	},

	now() {
		return dayjs()
	},
}

export default dateLib
