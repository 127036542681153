import Api from '~/helper/api'

const state = () => ({
	items: null,
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},

	SET_TOGGLE(state, payload) {
		const { parentIndex, advertiseIndex, value } = payload
		state.items[parentIndex].details[advertiseIndex].open = !value
	},

	SET_PRICE(state, payload) {
		const { value, parentIndex, advertiseIndex, lastIndex } = payload

		// Check value is < 0 or not number
		state.items[parentIndex].details[advertiseIndex].packages[
			lastIndex
		].price = value
	},

	SET_STATUS(state, payload) {
		const { status, parentIndex, advertiseIndex } = payload
		state.items[parentIndex].details[advertiseIndex].status = status
	},
}

const getters = {
	getItems: state => state.items,
}

const actions = {
	/**
	 * @description fetch all items when init page
	 * @param {payload} option
	 */
	async fetch({ commit }, payload) {
		try {
			const res = await this.$axios.get(Api('promo-list'))
			if (res.status === 200 && res.data.data) {
				res.data.data.forEach(e => {
					e.details.forEach(item => {
						item.open = false
						item.packages.forEach(i => {
							i.price = this.$_numeral(i.price).format('0,0')
						})
					})
				})

				commit('SET_ITEMS', res.data.data)
			} else {
				commit('SET_ITEMS', null)
			}
		} catch (error) {
			console.log(error)
		}
	},

	async updateStatus({ commit, dispatch }, payload) {
		const { id, status, parentIndex, advertiseIndex } = payload
		try {
			const res = await this.$axios.put(Api('promo-update-status', id), {
				status: status,
			})
			if (res.status === 200 && res.data) {
				commit('SET_STATUS', {
					status: res.data.data.status,
					parentIndex: parentIndex,
					advertiseIndex: advertiseIndex,
				})
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						persistent: true,
						message: 'Update status successfully',
					},
					{ root: true }
				)
			} else {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						persistent: true,
						message: 'Error, please try again.',
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},

	async updatePrice({ commit, dispatch }, payload) {
		try {
			const res = await this.$axios.put(Api('promo-update-price'), {
				packages: JSON.stringify(payload),
			})
			if (res.status === 200 && res.data) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						persistent: true,
						message: 'Update promo price successfully',
					},
					{ root: true }
				)
			} else {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						persistent: true,
						message: 'Error, please try again.',
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					persistent: true,
					message: error.message,
				},
				{ root: true }
			)
		}
	},
}

export default {
	state,
	mutations,
	getters,
	actions,
}
