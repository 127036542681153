import Api from '~/helper/api'

const state = () => ({
	items: [],
	sortItems: [],
	pagination: {
		current: 1,
		total: 0,
		perPage: 50,
	},
})

const getters = {
	getItems: state => state.items,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getSortItems: state => state.sortItems,
}

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current = payload.current
	},
	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},
	SET_SORT_ITEMS(state, payload) {
		state.sortItems = payload
	},
}

const actions = {
	async fetch({ commit, dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })

		try {
			const res = await this.$axios.get(Api('banners-fetch'), {
				params: {
					...payload.params,
				},
			})

			if (res.status === 200) {
				const items = res.data.data.map(x => {
					x.status = x.status === 'published'
					return x
				})
				commit('SET_ITEMS', items)
				commit('SET_PAGINATION', {
					current: res.data.current,
					total: res.data.total,
					perPage: 1,
				})
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: error },
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async create({ commit, dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })

		try {
			const form = new FormData()

			form.append('description', payload.description)
			form.append('end_time', payload.end_time)
			form.append('is_limited', payload.is_limited ? 1 : 0)
			form.append('link', payload.link)
			form.append('page', payload.page)
			form.append('position', payload.position)
			form.append('start_time', payload.start_time)
			form.append('thumbnail', payload.thumbnail)
			form.append('title', payload.title)
			payload.platform.forEach(x => {
				form.append('platform[]', x)
			})

			const res = await this.$axios.post(Api('banners-fetch'), form, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			if (res.status === 200 && res.data.result_code === 1) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: res.data.messages || 'Successfully',
						persistent: true,
						positiveCb: () => {
							commit('alert/RESET_STATE', null, { root: true })

							this.$router.push({
								name: 'banners-id-edit',
								params: {
									id: res.data.data.id,
								},
							})
						},
					},
					{ root: true }
				)
			} else {
				let error = ''
				for (const [, value] of Object.entries(res.data.data)) {
					error = error + value
				}
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: error || 'Error. Something when wrong!',
						positiveCb: () => {
							commit('alert/RESET_STATE', null, { root: true })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
					positiveCb:
						err.data && err.data.result_code === 403
							? () => {
									window.history.back()
							  }
							: null,
				},
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async updateStatus({ dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })
		const { id, status } = payload

		try {
			await this.$axios.put(Api('banner-detail', id), { status: status })
		} catch (error) {
			console.log(error)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async deleteBanner({ dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })
		const { id } = payload

		try {
			const res = await this.$axios.delete(Api('banner-detail', id))

			if (res.status === 200) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: res.data.messages || 'Successfully!',
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async updateBanner({ commit, dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })
		const { id, value } = payload

		try {
			const form = new FormData()
			if (value.description) {
				form.append('description', value.description)
			} else {
				form.append('description', '')
			}
			form.append('end_time', value.end_time)
			form.append('is_limited', value.is_limited ? 1 : 0)
			form.append('link', value.link)
			form.append('page', value.page)
			form.append('position', value.position)
			form.append('start_time', value.start_time)
			form.append('title', value.title)
			value.platform.forEach(x => {
				form.append('platform[]', x)
			})
			form.append('_method', 'patch')

			if (
				value.thumbnail &&
				!value.thumbnail.small &&
				!value.thumbnail.original
			) {
				form.append('thumbnail', value.thumbnail)
			}
			const res = await this.$axios.post(Api('banner-detail', id), form, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})

			if (res.status === 200 && res.data.result_code === 1) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: res.data.messages || 'Successfully!',
						positiveCb: () => {
							commit('alert/RESET_STATE', null, { root: true })

							this.$router.push({
								name: 'banners-id-edit',
								params: {
									id: res.data.data.id,
								},
							})
						},
					},
					{ root: true }
				)
			} else {
				let error = ''
				for (const [, value] of Object.entries(res.data.data)) {
					error = error + value
				}
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'error',
						message: error || 'Error. Something when wrong!',
						positiveCb: () => {
							commit('alert/RESET_STATE', null, { root: true })
						},
					},
					{ root: true }
				)
			}
		} catch (err) {
			dispatch(
				'alert/setAlert',
				{
					dialog: true,
					type: 'error',
					message: err.data ? err.data.result : err,
					positiveCb:
						err.data && err.data.result_code === 403
							? () => {
									window.history.back()
							  }
							: null,
				},
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async fetchSortItem({ commit, dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })

		try {
			const res = await this.$axios.get(Api('banners-fetch'), {
				params: { ...payload },
			})

			if (res.status === 200) {
				const sort = res.data.data.map(item => {
					return {
						id: item.id,
						path:
							item.thumbnail && item.thumbnail.original
								? item.thumbnail.original
								: '',
					}
				})

				commit('SET_SORT_ITEMS', sort)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: error },
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async saveSortItem({ dispatch }, payload) {
		const ids = payload.items.map(item => {
			return item.id
		})

		dispatch('common/setOverlay', true, { root: true })

		try {
			const res = await this.$axios.post(Api('banner-sort'), {
				params: ids,
			})
			if (res.status === 200) {
				dispatch(
					'alert/setAlert',
					{
						dialog: true,
						type: 'success',
						message: res.data.messages || 'Successfully!',
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'alert/setAlert',
				{ dialog: true, type: 'error', message: error },
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
