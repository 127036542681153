import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _301bb00f = () => interopDefault(import('../pages/banners/index.vue' /* webpackChunkName: "pages/banners/index" */))
const _70c33d5a = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _3c1daeab = () => interopDefault(import('../pages/checklist.vue' /* webpackChunkName: "pages/checklist" */))
const _1f9fd2c2 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _588cdfb5 = () => interopDefault(import('../pages/data.vue' /* webpackChunkName: "pages/data" */))
const _7f77fc67 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _270d81aa = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _eb60c3d0 = () => interopDefault(import('../pages/finance-loans/index.vue' /* webpackChunkName: "pages/finance-loans/index" */))
const _143b2dc2 = () => interopDefault(import('../pages/leads/index.vue' /* webpackChunkName: "pages/leads/index" */))
const _157cbf0b = () => interopDefault(import('../pages/loans/index.vue' /* webpackChunkName: "pages/loans/index" */))
const _3b5fdd64 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _610e74b4 = () => interopDefault(import('../pages/managers/index.vue' /* webpackChunkName: "pages/managers/index" */))
const _38c4f33e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _749cb80c = () => interopDefault(import('../pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _0d64f8b9 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _51da41d6 = () => interopDefault(import('../pages/popups/index.vue' /* webpackChunkName: "pages/popups/index" */))
const _f4a38b9c = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _d910a51c = () => interopDefault(import('../pages/promo-accounts/index.vue' /* webpackChunkName: "pages/promo-accounts/index" */))
const _f1935ecc = () => interopDefault(import('../pages/promo-alepay/index.vue' /* webpackChunkName: "pages/promo-alepay/index" */))
const _154099a6 = () => interopDefault(import('../pages/promo-cashes/index.vue' /* webpackChunkName: "pages/promo-cashes/index" */))
const _3a3c4345 = () => interopDefault(import('../pages/promo-overview/index.vue' /* webpackChunkName: "pages/promo-overview/index" */))
const _48bf7d70 = () => interopDefault(import('../pages/promo-products/index.vue' /* webpackChunkName: "pages/promo-products/index" */))
const _6ec97522 = () => interopDefault(import('../pages/promo-settings/index.vue' /* webpackChunkName: "pages/promo-settings/index" */))
const _5dbbe6a9 = () => interopDefault(import('../pages/qr-scanners/index.vue' /* webpackChunkName: "pages/qr-scanners/index" */))
const _9da3c302 = () => interopDefault(import('../pages/responsive.vue' /* webpackChunkName: "pages/responsive" */))
const _4cffa463 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _5060550e = () => interopDefault(import('../pages/role-permission/index.vue' /* webpackChunkName: "pages/role-permission/index" */))
const _260918cd = () => interopDefault(import('../pages/stations/index.vue' /* webpackChunkName: "pages/stations/index" */))
const _3b0893c0 = () => interopDefault(import('../pages/stores/index.vue' /* webpackChunkName: "pages/stores/index" */))
const _4a82e763 = () => interopDefault(import('../pages/transactions/index.vue' /* webpackChunkName: "pages/transactions/index" */))
const _7b183070 = () => interopDefault(import('../pages/user-managers/index.vue' /* webpackChunkName: "pages/user-managers/index" */))
const _09e2c6e2 = () => interopDefault(import('../pages/banners/create.vue' /* webpackChunkName: "pages/banners/create" */))
const _7f87d14d = () => interopDefault(import('../pages/banners/setting.vue' /* webpackChunkName: "pages/banners/setting" */))
const _4ae306f2 = () => interopDefault(import('../pages/category/create.vue' /* webpackChunkName: "pages/category/create" */))
const _20a91e4a = () => interopDefault(import('../pages/category/index.bk.vue' /* webpackChunkName: "pages/category/index.bk" */))
const _05da53e7 = () => interopDefault(import('../pages/category/index.bk2.vue' /* webpackChunkName: "pages/category/index.bk2" */))
const _d1884992 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _cee8aa10 = () => interopDefault(import('../pages/faqs/category/index.vue' /* webpackChunkName: "pages/faqs/category/index" */))
const _6b3a1e1a = () => interopDefault(import('../pages/faqs/create.vue' /* webpackChunkName: "pages/faqs/create" */))
const _48ff64f7 = () => interopDefault(import('../pages/login/change-password.vue' /* webpackChunkName: "pages/login/change-password" */))
const _9070df06 = () => interopDefault(import('../pages/news/create.vue' /* webpackChunkName: "pages/news/create" */))
const _c458e90a = () => interopDefault(import('../pages/news/create_bk.vue' /* webpackChunkName: "pages/news/create_bk" */))
const _1cb58d47 = () => interopDefault(import('../pages/news/create_bk2.vue' /* webpackChunkName: "pages/news/create_bk2" */))
const _54b5c9d2 = () => interopDefault(import('../pages/news/index_bk.vue' /* webpackChunkName: "pages/news/index_bk" */))
const _6b5e24e4 = () => interopDefault(import('../pages/notifications/create.vue' /* webpackChunkName: "pages/notifications/create" */))
const _46ae9432 = () => interopDefault(import('../pages/pages/create.vue' /* webpackChunkName: "pages/pages/create" */))
const _72232db6 = () => interopDefault(import('../pages/partners/create.vue' /* webpackChunkName: "pages/partners/create" */))
const _32fd4e49 = () => interopDefault(import('../pages/popups/create.vue' /* webpackChunkName: "pages/popups/create" */))
const _17734af3 = () => interopDefault(import('../pages/products/register.vue' /* webpackChunkName: "pages/products/register" */))
const _c15a1e0c = () => interopDefault(import('../pages/stations/orders.vue' /* webpackChunkName: "pages/stations/orders" */))
const _73ebbb10 = () => interopDefault(import('../pages/stations/register.vue' /* webpackChunkName: "pages/stations/register" */))
const _00ea3a1f = () => interopDefault(import('../pages/stations/vehicles/index.vue' /* webpackChunkName: "pages/stations/vehicles/index" */))
const _0d312d0c = () => interopDefault(import('../pages/transactions/transactions-details/index.vue' /* webpackChunkName: "pages/transactions/transactions-details/index" */))
const _0fa2284e = () => interopDefault(import('../pages/user-managers/create.vue' /* webpackChunkName: "pages/user-managers/create" */))
const _2b2424dc = () => interopDefault(import('../pages/user-managers/edit.vue' /* webpackChunkName: "pages/user-managers/edit" */))
const _bec40274 = () => interopDefault(import('../pages/faqs/category/create.vue' /* webpackChunkName: "pages/faqs/category/create" */))
const _4411547f = () => interopDefault(import('../pages/stations/vehicles/create.vue' /* webpackChunkName: "pages/stations/vehicles/create" */))
const _35ac0b9a = () => interopDefault(import('../pages/brands/models/model-details/create.vue' /* webpackChunkName: "pages/brands/models/model-details/create" */))
const _4fb978dd = () => interopDefault(import('../pages/brands/models/model-details/_id/edit.vue' /* webpackChunkName: "pages/brands/models/model-details/_id/edit" */))
const _25cb2d8a = () => interopDefault(import('../pages/stations/vehicles/_id/index.vue' /* webpackChunkName: "pages/stations/vehicles/_id/index" */))
const _74095dee = () => interopDefault(import('../pages/faqs/category/_id/edit.vue' /* webpackChunkName: "pages/faqs/category/_id/edit" */))
const _89626318 = () => interopDefault(import('../pages/stations/vehicles/_id/create.vue' /* webpackChunkName: "pages/stations/vehicles/_id/create" */))
const _59f01c82 = () => interopDefault(import('../pages/stations/vehicles/_id/edit.vue' /* webpackChunkName: "pages/stations/vehicles/_id/edit" */))
const _accaee68 = () => interopDefault(import('../pages/news/_id/index.vue' /* webpackChunkName: "pages/news/_id/index" */))
const _0ff1ceab = () => interopDefault(import('../pages/stores/_id/index.vue' /* webpackChunkName: "pages/stores/_id/index" */))
const _6d0398ce = () => interopDefault(import('../pages/transactions/_id/index.vue' /* webpackChunkName: "pages/transactions/_id/index" */))
const _46bba092 = () => interopDefault(import('../pages/banners/_id/edit.vue' /* webpackChunkName: "pages/banners/_id/edit" */))
const _19c20396 = () => interopDefault(import('../pages/category/_id/edit.vue' /* webpackChunkName: "pages/category/_id/edit" */))
const _e6d8558c = () => interopDefault(import('../pages/events/_id/edit.vue' /* webpackChunkName: "pages/events/_id/edit" */))
const _db672814 = () => interopDefault(import('../pages/faqs/_id/edit.vue' /* webpackChunkName: "pages/faqs/_id/edit" */))
const _c373132c = () => interopDefault(import('../pages/finance-loans/_id/view-detail.vue' /* webpackChunkName: "pages/finance-loans/_id/view-detail" */))
const _4d39a3ad = () => interopDefault(import('../pages/loans/_id/disbursement.vue' /* webpackChunkName: "pages/loans/_id/disbursement" */))
const _2062ba7e = () => interopDefault(import('../pages/loans/_id/paperwork.vue' /* webpackChunkName: "pages/loans/_id/paperwork" */))
const _8df15e00 = () => interopDefault(import('../pages/news/_id/edit.vue' /* webpackChunkName: "pages/news/_id/edit" */))
const _10914fd0 = () => interopDefault(import('../pages/news/_id/edit_bk.vue' /* webpackChunkName: "pages/news/_id/edit_bk" */))
const _0169576c = () => interopDefault(import('../pages/news/_id/edit_bk2.vue' /* webpackChunkName: "pages/news/_id/edit_bk2" */))
const _2a3c3b75 = () => interopDefault(import('../pages/pages/_id/edit.vue' /* webpackChunkName: "pages/pages/_id/edit" */))
const _cc4ac0b0 = () => interopDefault(import('../pages/partners/_id/edit.vue' /* webpackChunkName: "pages/partners/_id/edit" */))
const _3dc4cbcc = () => interopDefault(import('../pages/popups/_id/edit.vue' /* webpackChunkName: "pages/popups/_id/edit" */))
const _507a9b53 = () => interopDefault(import('../pages/products/_id/preview.vue' /* webpackChunkName: "pages/products/_id/preview" */))
const _56a0f762 = () => interopDefault(import('../pages/promo-accounts/_id/edit.vue' /* webpackChunkName: "pages/promo-accounts/_id/edit" */))
const _1d4b9714 = () => interopDefault(import('../pages/stations/_id/edit.vue' /* webpackChunkName: "pages/stations/_id/edit" */))
const _5e9a4416 = () => interopDefault(import('../pages/transactions/_id/view-detail.vue' /* webpackChunkName: "pages/transactions/_id/view-detail" */))
const _f6c98ede = () => interopDefault(import('../pages/user-managers/_id/edit.vue' /* webpackChunkName: "pages/user-managers/_id/edit" */))
const _57a54d2a = () => interopDefault(import('../pages/transactions/_id/credit/_loanId/index.vue' /* webpackChunkName: "pages/transactions/_id/credit/_loanId/index" */))
const _63692847 = () => interopDefault(import('../pages/transactions/_id/e-contract/_eContractId/index.vue' /* webpackChunkName: "pages/transactions/_id/e-contract/_eContractId/index" */))
const _768be96a = () => interopDefault(import('../pages/transactions/_id/finances/_loanId/index.vue' /* webpackChunkName: "pages/transactions/_id/finances/_loanId/index" */))
const _3ed04f15 = () => interopDefault(import('../pages/transactions/_id/e-contract/_eContractId/paperwork/index.vue' /* webpackChunkName: "pages/transactions/_id/e-contract/_eContractId/paperwork/index" */))
const _b529940c = () => interopDefault(import('../pages/transactions/_id/finances/_loanId/disbursed.vue' /* webpackChunkName: "pages/transactions/_id/finances/_loanId/disbursed" */))
const _41c1df16 = () => interopDefault(import('../pages/transactions/_id/finances/_loanId/paperwork.vue' /* webpackChunkName: "pages/transactions/_id/finances/_loanId/paperwork" */))
const _62b6beb2 = () => interopDefault(import('../pages/transactions/_id/finances/_loanId/view-detail.vue' /* webpackChunkName: "pages/transactions/_id/finances/_loanId/view-detail" */))
const _bd936352 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/banners",
    component: _301bb00f,
    name: "banners"
  }, {
    path: "/brands",
    component: _70c33d5a,
    name: "brands"
  }, {
    path: "/checklist",
    component: _3c1daeab,
    name: "checklist"
  }, {
    path: "/comments",
    component: _1f9fd2c2,
    name: "comments"
  }, {
    path: "/data",
    component: _588cdfb5,
    name: "data"
  }, {
    path: "/events",
    component: _7f77fc67,
    name: "events"
  }, {
    path: "/faqs",
    component: _270d81aa,
    name: "faqs"
  }, {
    path: "/finance-loans",
    component: _eb60c3d0,
    name: "finance-loans"
  }, {
    path: "/leads",
    component: _143b2dc2,
    name: "leads"
  }, {
    path: "/loans",
    component: _157cbf0b,
    name: "loans"
  }, {
    path: "/Login",
    component: _3b5fdd64,
    name: "Login"
  }, {
    path: "/managers",
    component: _610e74b4,
    name: "managers"
  }, {
    path: "/news",
    component: _38c4f33e,
    name: "news"
  }, {
    path: "/pages",
    component: _749cb80c,
    name: "pages"
  }, {
    path: "/partners",
    component: _0d64f8b9,
    name: "partners"
  }, {
    path: "/popups",
    component: _51da41d6,
    name: "popups"
  }, {
    path: "/products",
    component: _f4a38b9c,
    name: "products"
  }, {
    path: "/promo-accounts",
    component: _d910a51c,
    name: "promo-accounts"
  }, {
    path: "/promo-alepay",
    component: _f1935ecc,
    name: "promo-alepay"
  }, {
    path: "/promo-cashes",
    component: _154099a6,
    name: "promo-cashes"
  }, {
    path: "/promo-overview",
    component: _3a3c4345,
    name: "promo-overview"
  }, {
    path: "/promo-products",
    component: _48bf7d70,
    name: "promo-products"
  }, {
    path: "/promo-settings",
    component: _6ec97522,
    name: "promo-settings"
  }, {
    path: "/qr-scanners",
    component: _5dbbe6a9,
    name: "qr-scanners"
  }, {
    path: "/responsive",
    component: _9da3c302,
    name: "responsive"
  }, {
    path: "/reviews",
    component: _4cffa463,
    name: "reviews"
  }, {
    path: "/role-permission",
    component: _5060550e,
    name: "role-permission"
  }, {
    path: "/stations",
    component: _260918cd,
    name: "stations"
  }, {
    path: "/stores",
    component: _3b0893c0,
    name: "stores"
  }, {
    path: "/transactions",
    component: _4a82e763,
    name: "transactions"
  }, {
    path: "/user-managers",
    component: _7b183070,
    name: "user-managers"
  }, {
    path: "/banners/create",
    component: _09e2c6e2,
    name: "banners-create"
  }, {
    path: "/banners/setting",
    component: _7f87d14d,
    name: "banners-setting"
  }, {
    path: "/category/create",
    component: _4ae306f2,
    name: "category-create"
  }, {
    path: "/category/index.bk",
    component: _20a91e4a,
    name: "category-index.bk"
  }, {
    path: "/category/index.bk2",
    component: _05da53e7,
    name: "category-index.bk2"
  }, {
    path: "/events/create",
    component: _d1884992,
    name: "events-create"
  }, {
    path: "/faqs/category",
    component: _cee8aa10,
    name: "faqs-category"
  }, {
    path: "/faqs/create",
    component: _6b3a1e1a,
    name: "faqs-create"
  }, {
    path: "/login/change-password",
    component: _48ff64f7,
    name: "login-change-password"
  }, {
    path: "/news/create",
    component: _9070df06,
    name: "news-create"
  }, {
    path: "/news/create_bk",
    component: _c458e90a,
    name: "news-create_bk"
  }, {
    path: "/news/create_bk2",
    component: _1cb58d47,
    name: "news-create_bk2"
  }, {
    path: "/news/index_bk",
    component: _54b5c9d2,
    name: "news-index_bk"
  }, {
    path: "/notifications/create",
    component: _6b5e24e4,
    name: "notifications-create"
  }, {
    path: "/pages/create",
    component: _46ae9432,
    name: "pages-create"
  }, {
    path: "/partners/create",
    component: _72232db6,
    name: "partners-create"
  }, {
    path: "/popups/create",
    component: _32fd4e49,
    name: "popups-create"
  }, {
    path: "/products/register",
    component: _17734af3,
    name: "products-register"
  }, {
    path: "/stations/orders",
    component: _c15a1e0c,
    name: "stations-orders"
  }, {
    path: "/stations/register",
    component: _73ebbb10,
    name: "stations-register"
  }, {
    path: "/stations/vehicles",
    component: _00ea3a1f,
    name: "stations-vehicles"
  }, {
    path: "/transactions/transactions-details",
    component: _0d312d0c,
    name: "transactions-transactions-details"
  }, {
    path: "/user-managers/create",
    component: _0fa2284e,
    name: "user-managers-create"
  }, {
    path: "/user-managers/edit",
    component: _2b2424dc,
    name: "user-managers-edit"
  }, {
    path: "/faqs/category/create",
    component: _bec40274,
    name: "faqs-category-create"
  }, {
    path: "/stations/vehicles/create",
    component: _4411547f,
    name: "stations-vehicles-create"
  }, {
    path: "/brands/models/model-details/create",
    component: _35ac0b9a,
    name: "brands-models-model-details-create"
  }, {
    path: "/brands/models/model-details/:id?/edit",
    component: _4fb978dd,
    name: "brands-models-model-details-id-edit"
  }, {
    path: "/stations/vehicles/:id",
    component: _25cb2d8a,
    name: "stations-vehicles-id"
  }, {
    path: "/faqs/category/:id/edit",
    component: _74095dee,
    name: "faqs-category-id-edit"
  }, {
    path: "/stations/vehicles/:id/create",
    component: _89626318,
    name: "stations-vehicles-id-create"
  }, {
    path: "/stations/vehicles/:id/edit",
    component: _59f01c82,
    name: "stations-vehicles-id-edit"
  }, {
    path: "/news/:id",
    component: _accaee68,
    name: "news-id"
  }, {
    path: "/stores/:id",
    component: _0ff1ceab,
    name: "stores-id"
  }, {
    path: "/transactions/:id",
    component: _6d0398ce,
    name: "transactions-id"
  }, {
    path: "/banners/:id/edit",
    component: _46bba092,
    name: "banners-id-edit"
  }, {
    path: "/category/:id?/edit",
    component: _19c20396,
    name: "category-id-edit"
  }, {
    path: "/events/:id/edit",
    component: _e6d8558c,
    name: "events-id-edit"
  }, {
    path: "/faqs/:id/edit",
    component: _db672814,
    name: "faqs-id-edit"
  }, {
    path: "/finance-loans/:id?/view-detail",
    component: _c373132c,
    name: "finance-loans-id-view-detail"
  }, {
    path: "/loans/:id/disbursement",
    component: _4d39a3ad,
    name: "loans-id-disbursement"
  }, {
    path: "/loans/:id/paperwork",
    component: _2062ba7e,
    name: "loans-id-paperwork"
  }, {
    path: "/news/:id/edit",
    component: _8df15e00,
    name: "news-id-edit"
  }, {
    path: "/news/:id/edit_bk",
    component: _10914fd0,
    name: "news-id-edit_bk"
  }, {
    path: "/news/:id/edit_bk2",
    component: _0169576c,
    name: "news-id-edit_bk2"
  }, {
    path: "/pages/:id/edit",
    component: _2a3c3b75,
    name: "pages-id-edit"
  }, {
    path: "/partners/:id/edit",
    component: _cc4ac0b0,
    name: "partners-id-edit"
  }, {
    path: "/popups/:id/edit",
    component: _3dc4cbcc,
    name: "popups-id-edit"
  }, {
    path: "/products/:id/preview",
    component: _507a9b53,
    name: "products-id-preview"
  }, {
    path: "/promo-accounts/:id?/edit",
    component: _56a0f762,
    name: "promo-accounts-id-edit"
  }, {
    path: "/stations/:id/edit",
    component: _1d4b9714,
    name: "stations-id-edit"
  }, {
    path: "/transactions/:id/view-detail",
    component: _5e9a4416,
    name: "transactions-id-view-detail"
  }, {
    path: "/user-managers/:id?/edit",
    component: _f6c98ede,
    name: "user-managers-id-edit"
  }, {
    path: "/transactions/:id/credit/:loanId",
    component: _57a54d2a,
    name: "transactions-id-credit-loanId"
  }, {
    path: "/transactions/:id/e-contract/:eContractId",
    component: _63692847,
    name: "transactions-id-e-contract-eContractId"
  }, {
    path: "/transactions/:id/finances/:loanId",
    component: _768be96a,
    name: "transactions-id-finances-loanId"
  }, {
    path: "/transactions/:id/e-contract/:eContractId?/paperwork",
    component: _3ed04f15,
    name: "transactions-id-e-contract-eContractId-paperwork"
  }, {
    path: "/transactions/:id/finances/:loanId?/disbursed",
    component: _b529940c,
    name: "transactions-id-finances-loanId-disbursed"
  }, {
    path: "/transactions/:id/finances/:loanId?/paperwork",
    component: _41c1df16,
    name: "transactions-id-finances-loanId-paperwork"
  }, {
    path: "/transactions/:id/finances/:loanId?/view-detail",
    component: _62b6beb2,
    name: "transactions-id-finances-loanId-view-detail"
  }, {
    path: "/",
    component: _bd936352,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
