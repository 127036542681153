<template>
	<v-row no-gutters class="sidebar__userinfo text-center">
		<v-col>
			<v-avatar size="82" class="sidebar__userinfo-avatar">
				<v-img v-if="avatar" :src="avatar" />
				<v-icon v-else size="82" color="grey lighten-1">
					account_circle
				</v-icon>
			</v-avatar>
			<div class="sidebar__userinfo-name">
				{{ username }}
			</div>
			<div
				v-if="userInfo.role && userInfo.role.length > 0"
				class="sidebar__userinfo-role"
			>
				{{ role }}
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	computed: {
		userInfo() {
			return this.$store.getters['auth/getUserInfo']
		},
		username() {
			return this.userInfo?.full_name || 'No name'
		},
		role() {
			if (this.userInfo && this.userInfo.role.length > 0) {
				return this.userInfo.role[0].display_name
			}
			return 'No role'
		},
		avatar() {
			return this.userInfo?.avatar
				? this.userInfo?.avatar[0]?.medium
				: null
		},
	},
}
</script>
