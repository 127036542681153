const resource = '/users'
const resourceUpdate = 'update_status'
const resourceReset = 'reset_password'
export default $axios => ({
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	create(payload) {
		return $axios.post(`${resource}`, payload)
	},
	update(id, payload) {
		return $axios.put(`${resource}/${resourceUpdate}/${id}`, payload)
	},
	resetPassword(id) {
		return $axios.put(`${resource}/${resourceReset}/${id}`)
	},
	getUserById(id) {
		return $axios.get(`${resource}/${id}`)
	},
	edit(id, payload) {
		return $axios.post(`${resource}/${id}`, payload)
	},
	getUserByKeyword(k) {
		let url = `${resource}/search/user-admin`
		if (k) {
			url += '?name=' + k
		}
		return $axios.get(url)
	},
})
