const getDefaultState = () => {
	return {
		tabs: [],
		contractID: null,
		tabActice: 0,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getTabs: state => state.tabs,
	getTabActice: state => state.tabActice,
}

const actions = {
	setTabs({ commit }, payload) {
		commit('SET_TABS', payload)
	},
	setTabActive({ commit }, payload) {
		commit('SET_TAB_ACTIVE', payload)
	},
}

const mutations = {
	SET_TABS(state, payload) {
		state.tabs = payload
	},
	SET_TAB_ACTIVE(state, payload) {
		state.tabActice = payload
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
