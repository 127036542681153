// import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		storesOfStation: [],
		query: {
			type: null,
			keyword: null,
			sort_by: null,
			order_by: null,
			count: null,
			page: null,
		},
		filter: {
			within_day: null,
			range_date: null,
			start_date: null,
			end_date: null,
			location: null,
			display: null,
			own_products: null,
			sold_products: null,
			purchased_products: null,
			followers: null,
		},
		pagination: {
			current: 1,
			total: 0,
			perPage: 50,
		},
		loading: false,
		totalValue: 0,
		storeInfo: null,
	}
}

const state = () => getDefaultState()

const getters = {
	getStores: state => state.storesOfStation.map(item => 
		({
			text: item.name,
			value: item.id.toString(),
		}
	)),
	getTotalValue: state => state.totalValue,
	getLoading: state => state.loading,
	getFilter: state => state.filter,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getStoreInfo: state => state.storeInfo,
	getStationStore: state =>
		state.storesOfStation.filter(state => state.is_station === 2),
}

const actions = {
	// async search({ commit, dispatch }, payload) {
	// 	try {
	// 		commit('setLoading', true)
	// 		const res = await this.$axios.get(Api('store-search'), {
	// 			params: {
	// 				...payload.params,
	// 				count: 50,
	// 			},
	// 		})
	// 		if (res.status === 200) {
	// 			const data = res.data.data
	// 			commit('setStoresOfStation', data)
	// 		}
	// 	} catch (err) {
	// 		if (
	// 			err &&
	// 			err.message === 'Operation canceled by the Route change.'
	// 		) {
	// 			console.log('Operation canceled by the Route change.')
	// 		} else {
	// 			dispatch(
	// 				'common/setAlert',
	// 				{
	// 					dialog: true,
	// 					type: 'error',
	// 					messages: err.data ? err.data.result : err,
	// 				},
	// 				{ root: true }
	// 			)
	// 		}
	// 	} finally {
	// 		commit('setLoading', false)
	// 	}
	// },
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setStoresOfStation(state, data) {
		state.storesOfStation = [...data]
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
