import Cookie from 'js-cookie'
export default async function({ store, isHMR }) {
	// console.log('hello', process.client)
	// get token
	const token = Cookie.get('token')
	if (!token) {
		store.commit('auth/setIsAuth', false)
		return
	}

	// check token

	// get user info
	store.commit('auth/setIsAuth', true)
	if (!store.getters['auth/getUserInfo'].id) {
		await store.dispatch('auth/getUserInfo')
	}
}
