<template>
	<v-card max-width="438" max-height="350" class="alert-dialog mx-auto">
		<span class="icon-big-check" style="font-size:70px" />
		<div class="alert-dialog__title text-center">
			{{ title || 'Success' }}
		</div>
		<div class="alert-dialog__message text-center">
			{{ message || 'Successful action' }}
		</div>
		<v-row no-gutters class="alert-dialog__action">
			<v-col class="alert-dialog__action--close ml-2">
				<v-btn
					height="40px"
					width="172px"
					depressed
					color="#F0F1F3"
					class="button-action"
					@click="onClickPositive"
				>
					{{ positiveText || 'Close' }}
				</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		message: {
			type: String,
			default: 'You cannot undo this',
		},
		positiveText: {
			type: String,
			default: 'You cannot undo this',
		},
		positiveCb: {
			type: Function,
			default: () => {},
		},
	},

	methods: {
		onClickPositive() {
			if (this.positiveCb) {
				this.positiveCb()
			}
			this.$store.dispatch('alert/setCloseAlert')
		},
	},
}
</script>
