<template>
	<v-row no-gutters class="notice-bar">
		<span class="notice-bar__logo">NOTICE</span>
		<span class="notice-bar__post-title ml-o-5">{{ message }}</span>
		<!-- <span class="notice-bar__post-date ml-o-5">2020.03.31</span> -->
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			message: '',
		}
	},

	created() {
		this.message = process.env.NOTICE_MESSAGE || ''
	},
}
</script>
