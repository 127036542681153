<template>
	<div class="error-container">
		<div class="error-left">
			<h1 style="font-size: 150px;">
				{{ error.statusCode }}
			</h1>
			<h1 style="margin-bottom:20px">
				Whoops! Sorry about that.
			</h1>
			<h1 style="font-size: 20px;margin-bottom:20px">
				{{ error.message }}
			</h1>
			<h1 style="font-size: 20px;">
				{{ error.path }}
			</h1>
			<v-btn width="170px" class="mt-4" @click="goToHomepage">
				Go To Homepage
			</v-btn>
		</div>
		<div class="error-right">
			<span v-show="false">hello</span>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	layout(context) {
		return 'Blank'
	},

	props: {
		error: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			pageNotFound: '404 Not Found',
			otherError: 'An error occurred',
		}
	},

	head() {
		const title =
			this.error.statusCode === 404 ? this.pageNotFound : this.otherError
		return {
			title,
		}
	},

	methods: {
		goToHomepage() {
			window.location.href = '/'
		},
	},
}
</script>

<style lang="sass" scoped>
.error-container
	height: 100%
	display: flex
	background-color: white
	font-family: 'roboto'
	flex-wrap: wrap

	.error-left
		width: 50%
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center

	.error-right
		width: 50%
		background-image: url('https://res.cloudinary.com/dhthbc2wn/image/upload/v1587615581/admin_static/bg/starman_750x750_2x-282aab029fa256114db6a4d7b1e4f199_alxfq1.png')
		background-size: contain
		background-repeat: no-repeat
		background-position: center
</style>
