/* eslint-disable dot-notation */
// import Cookie from 'js-cookie'
// import cookieParser from 'cookie-parser'

// import Api from '~/helper/api'
// import cookieHelper from '~/helper/cookieHelper'

export const actions = {
	// nuxtServerInit({ commit, dispatch }, { $axios, req, isDev, redirect }) {
	// 	const cookie = cookieHelper.parse(req?.headers?.cookie)
	// 	if (!cookie) { return }
	// 	if (!cookie.token) {
	// 		commit('auth/setIsAuth', false)
	// 		return
	// 	}
	// 	// verify token on server
	// 	// set auth
	// 	commit('auth/setIsAuth', true)
	// 	// await dispatch('auth/getUserInfo')
	// 	// fetch user data + role + menu
	// 	// dispatch('user/getUserInfo')
	// 	// try {
	// 	// 	const res = await $axios.get(Api('users-get-info'), {
	// 	// 		headers: {
	// 	// 			Authorization: `Bearer ${cookie.token}`
	// 	// 		}
	// 	// 	})
	// 	// 	commit('auth/setUserInfo', {
	// 	// 		data: res.data.data
	// 	// 	})
	// 	// } catch (err) {
	// 	// 	console.log(err)
	// 	// }
	// }
}
