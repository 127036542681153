const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['checkAuth'] = require('../middleware/checkAuth.js')
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['checkPassword'] = require('../middleware/checkPassword.js')
middleware['checkPassword'] = middleware['checkPassword'].default || middleware['checkPassword']

middleware['isAuth'] = require('../middleware/isAuth.js')
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

middleware['log'] = require('../middleware/log.js')
middleware['log'] = middleware['log'].default || middleware['log']

export default middleware
