const resource = '/popups'

export default $axios => ({
	fetch() {
		return $axios.get(`${resource}`)
	},
	find(payload) {
		return $axios.get(`${resource}`, payload)
	},
	changeStatus(id, payload) {
		return $axios.put(`${resource}/${id}/change-status`, payload)
	},
	delete(id) {
		return $axios.delete(`${resource}/${id}`)
	},
	create(payload) {
		return $axios.post(`${resource}`, payload)
	},
	show(id) {
		return $axios.get(`${resource}/${id}`)
	},
	update(id, payload, headers) {
		return $axios.post(`${resource}/${id}`, payload, headers)
	},
})
