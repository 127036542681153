import Api from '~/helper/api'

const state = () => ({
	items: [],
	bankAccountDialog: false,
	loading: true,
	pagination: {
		current: 1,
		total: 0,
		perPage: 50,
	},
	buyerInfo: {},
	overlay: false,
	historyInfo: {},
	productInfo: {},
	paperworkInfo: null,
	disburInfo: null,
})

const mutations = {
	SET_ITEMS(state, payload) {
		state.items = payload
	},
	SET_DIALOG_BANK(state, payload) {
		state.bankAccountDialog = payload
	},
	SET_LOADING(state, payload) {
		state.loading = payload
	},
	SET_PAGINATION(state, payload) {
		state.pagination = { ...payload }
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current = payload.current
	},
	SET_BUYER_INFO(state, payload) {
		state.buyerInfo = payload
	},
	SET_OVERLAY(state, payload) {
		state.overlay = payload
	},
	SET_HISTORY_INFO(state, payload) {
		state.historyInfo = payload
	},
	SET_PRODUCT_INFO(state, payload) {
		state.productInfo = payload
	},
	SET_PAPERWORK(state, payload) {
		state.paperworkInfo = payload
	},
	SET_PAPERWORK_CONFIRM(state, payload) {
		const paperwork = ['paperwork_buyer', 'paperwork_seller']

		paperwork.forEach(item => {
			if (
				state.paperworkInfo[item] &&
				state.paperworkInfo[item].id === payload.id
			) {
				state.paperworkInfo[item] = { ...payload }
			}
		})
	},
	SET_DISBURSEMENT_INFO(state, payload) {
		state.disburInfo = payload
	},
	CHANGE_MONEY(state, payload) {
		state.disburInfo.money = payload
	},
	CHANGE_DISBUR_CONTRACT_NO(state, payload) {
		state.disburInfo.contract_no = payload
	},
}

const getters = {
	getItems: state => state.items,
	getLoading: state => state.loading,
	getBankAccountDialog: state => state.bankAccountDialog,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getBuyerInfo: state => state.buyerInfo,
	getLoanStatus: state => state.buyerInfo.loan_status || '',
	getFrontImage: state =>
		state.buyerInfo.identity_card_image &&
		state.buyerInfo.identity_card_image.front_image
			? state.buyerInfo.identity_card_image.front_image.original
			: '',
	getBackImage: state =>
		state.buyerInfo.identity_card_image &&
		state.buyerInfo.identity_card_image.back_image
			? state.buyerInfo.identity_card_image.back_image.original
			: '',
	getOverlay: state => state.overlay,
	getHistoryInfo: state => state.historyInfo,
	getProductInfo: state => state.productInfo,
	getProductImages: state => state.productInfo.images,
	getPaperwork: state => state.paperworkInfo,
	getDisburInfo: state => state.disburInfo,
}

const actions = {
	setDiaglogBank({ commit }, payload) {
		commit('SET_DIALOG_BANK', payload)
	},

	async fetch({ commit, dispatch }, payload) {
		dispatch('common/setOverlay', true, { root: true })
		commit('SET_LOADING', true)

		try {
			const res = await this.$axios.get(Api('loan-fetch'), {
				params: {
					...payload.params,
				},
			})

			if (res.status === 200) {
				const data = res.data.data
				commit('SET_ITEMS', data)
				commit('SET_PAGINATION', {
					current: res.data.current,
					total: res.data.total,
					perPage: 1,
				})
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: error },
				{ root: true }
			)
		} finally {
			commit('SET_LOADING', false)
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async updateBankAccount({ commit }, param) {
		try {
			const { id, account, accountName, bankName, type } = param
			let data = {}
			if (type === 'seller_bank_account') {
				data = {
					seller_bank_account: account,
					seller_bank_account_name: accountName,
					seller_bank_name: bankName,
				}
			} else {
				data = {
					buyer_bank_account: account,
					buyer_bank_account_name: accountName,
					buyer_bank_name: bankName,
				}
			}
			await this.$axios.put(Api('loan-update-bank-account', id), data)
		} catch (error) {
			console.log(error)
		} finally {
			commit('SET_DIALOG_BANK', false)
		}
	},

	async fetchBuyer({ commit }, param) {
		commit('SET_OVERLAY', true)

		try {
			const data = await this.$axios.get(Api('loan-get-buyer', param))

			if (data.status === 200 && data.data.data) {
				commit('SET_BUYER_INFO', data.data.data)
			}
		} catch (error) {
			console.log(error)
		} finally {
			commit('SET_OVERLAY', false)
		}
	},

	async updateLoanStatus({ dispatch }, param) {
		const { id, status } = param
		dispatch('common/setOverlay', true, { root: true })

		try {
			const res = await this.$axios.put(Api('loan-update-status', id), {
				loan_status: status,
			})

			if (res.data.result_code && res.data.result_code === 0) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: res.data.messages,
					},
					{ root: true }
				)
			}

			if (res.data.result_code && res.data.result_code === 1) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: res.data.messages,
					},
					{ root: true }
				)
			}

			if (res.data.result_code && res.data.result_code === 2) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'warning',
						messages: res.data.messages,
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: error.data ? error.data.result : error,
				},
				{ root: true }
			)
		} finally {
			dispatch('common/setOverlay', false, { root: true })
		}
	},

	async fetchHistory({ commit, dispatch }, param) {
		commit('SET_OVERLAY', true)

		try {
			const data = await this.$axios.get(Api('loan-get-history', param))

			if (data.status === 200 && data.data.data.history) {
				commit('SET_HISTORY_INFO', data.data.data.history)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: error },
				{ root: true }
			)
		} finally {
			commit('SET_OVERLAY', false)
		}
	},

	async fetchProduct({ commit, dispatch }, param) {
		commit('SET_OVERLAY', true)

		try {
			const data = await this.$axios.get(Api('loan-get-product', param))

			if (data.status === 200 && data.data) {
				commit('SET_PRODUCT_INFO', data.data.data)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{ dialog: true, type: 'error', messages: error },
				{ root: true }
			)
		} finally {
			commit('SET_OVERLAY', false)
		}
	},

	async fetchPaperwork({ commit, dispatch }, param) {
		try {
			const data = await this.$axios.get(Api('loan-get-paperwork', param))

			if (data.status === 200 && data.data) {
				commit('SET_PAPERWORK', data.data.data)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: error.data ? error.data.result : error,
				},
				{ root: true }
			)
		}
	},

	async confirmPaperwork({ commit, dispatch }, param) {
		try {
			const res = await this.$axios.post(
				Api('loan-confirm-paperwork', param.id),
				{ approve: param.approve, reason: param.reason }
			)

			if (res.data && res.data.result_code === 1) {
				commit('SET_PAPERWORK_CONFIRM', res.data.data)
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: 'Successully',
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
						},
					},
					{ root: true }
				)
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: res.data?.result,
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
						},
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: error.data ? error.data.result : error,
					fbAction: () => {
						dispatch(
							'common/setAlert',
							{ dialog: false },
							{ root: true }
						)
						this.$router.push({
							name: 'loans',
						})
					},
				},
				{ root: true }
			)
		}
	},

	async fetchDisbursement({ commit }, param) {
		try {
			const data = await this.$axios.get(
				Api('loan-disbursement-info', param.id)
			)
			if (data.status === 200 && data.data.data) {
				commit('SET_DISBURSEMENT_INFO', data.data.data)
			} else {
				commit('SET_DISBURSEMENT_INFO', { money: '', contract_no: '' })
			}
		} catch (error) {
			console.log(error)
		}
	},

	async updateDisbursement({ dispatch }, param) {
		try {
			const data = await this.$axios.post(
				Api('loan-disbursement-info', param.id),
				param.params
			)
			if (data.data.result_code !== 1) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'error',
						messages: data.data.result,
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
							this.$router.push({ name: 'loans' })
						},
					},
					{ root: true }
				)
			} else {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: 'Successully',
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
							this.$router.push({ name: 'loans' })
						},
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: error.data ? error.data.result : error,
					fbAction: () => {
						dispatch(
							'common/setAlert',
							{ dialog: false },
							{ root: true }
						)
						this.$router.push({
							name: 'loans',
						})
					},
				},
				{ root: true }
			)
		}
	},

	async disbursementNotification({ dispatch }, param) {
		try {
			const data = await this.$axios.post(
				Api('loan-disbursement-notification', param.id)
			)
			if (data.status === 200) {
				dispatch(
					'common/setAlert',
					{
						dialog: true,
						type: 'success',
						messages: 'Successully',
						fbAction: () => {
							dispatch(
								'common/setAlert',
								{ dialog: false },
								{ root: true }
							)
						},
					},
					{ root: true }
				)
			}
		} catch (error) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: error.data ? error.data.result : error,
					fbAction: () => {
						dispatch(
							'common/setAlert',
							{ dialog: false },
							{ root: true }
						)
						this.$router.push({
							name: 'loans',
						})
					},
				},
				{ root: true }
			)
		}
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
