import Cookie from 'js-cookie'

export default function({ $axios, isDev, store }) {
	// set default header
	$axios.defaults.headers.common['Content-Type'] = 'application/json'
	$axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
	// set axios timeout
	if (!isDev) {
		$axios.defaults.timeout = 60000
	}

	// get auth token
	const token = Cookie.get('token')

	if (token) {
		$axios.setToken(token, 'Bearer')
	}

	// set cancel source
	store.dispatch('axios/refreshToken')

	// Request interceptor
	$axios.onRequest(config => {
		config = {
			...config,
			cancelToken: store.getters['axios/getCancelToken'], // set token
		}
		// if (isDev) {
		// 	console.log('Making request to ' + config.url)
		// }

		return config
	})
	// check response
	$axios.onResponse(response => {
		if (response.data.result_code === 6002) {
			store.dispatch('auth/logout')
		}

		if (response.data.result_code === 403) {
			store.dispatch('alert/setAlert', {
				dialog: true,
				type: 'error',
				message: response.data.result,
			})
			return Promise.reject(response)
		}
	})
	// Error interceptor
	$axios.onError(error => {
		if (isDev) {
			// const code = parseInt(error.response && error.response.status)
			// if (code === 404) {
			// 	console.log('Not Found')
			// }
			// if (code === 422) {
			// 	console.log('Bad Request')
			// }
			// console.log('axios data: ', error.data)
		}

		if (error.status === 401 && window.location.pathname !== '/login') {
			// store.dispatch('auth/logout')
			Cookie.remove('token')
			Cookie.remove('userId')
			window.location.href = '/login'
		}

		if (error.status === 500) {
			store.dispatch('alert/setAlert', {
				dialog: true,
				type: 'error',
				message: 'Server Error',
			})
		}

		return Promise.reject(error)
	})
}
