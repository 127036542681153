import Api from '~/helper/api'
const getDefaultState = () => {
	return {
		userList: [],
		loading: false,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getUserList: state => state.userList,
	getLoading: state => state.loading,
}

const actions = {
	async fetch({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api['user-fetch'])
			if (res.status === 200) {
				commit('setUserList', res.data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setUserList(state, list) {
		state.userList = list
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
