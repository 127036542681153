<template>
	<div class="text-center">
		<v-menu offset-y>
			<template #activator="{ on }">
				<v-btn text width="140px" v-on="on">
					<img
						width="25px"
						:src="`/images/${locale.flag}.svg`"
						style="margin-right: 5px"
					/>
					{{ locale.name }}
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-for="(item, index) in $i18n.locales"
					:key="index + item.iso"
					@click="onSwitchLang(item)"
				>
					<v-list-item-title>{{ item.name }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</div>
</template>

<script>
import Cookie from 'js-cookie'

export default {
	data() {
		return {}
	},
	computed: {
		locale() {
			const locale = {
				name: '',
				flag: '',
			}
			if (this.$i18n.locale === 'vi') {
				locale.name = 'Tiếng Việt'
				locale.flag = 'flag-vi'
				return locale
			}
			if (this.$i18n.locale === 'en') {
				locale.name = 'English'
				locale.flag = 'flag-en'
				return locale
			}
			return locale
		},
	},
	created() {
		if (!Cookie.get('locale')) {
			Cookie.set('locale', 'en')
		}
		this.$i18n.locale = Cookie.get('locale')
	},
	mounted() {},
	methods: {
		onSwitchLang(item) {
			if (item && this.$i18n.locale !== item.code) {
				this.$i18n.locale = item.code
				Cookie.set('locale', item.code)
			}
		},
	},
}
</script>
