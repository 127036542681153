import StoreRepository from '~/repositories/StoreRepository'
import NewsRepository from '~/repositories/NewsRepository'
import UserRepository from '~/repositories/UserRepository'
import CategoryRepository from '~/repositories/CategoryRepository'
import CommentRepository from '~/repositories/CommentRepository'
import FaqsRepository from '~/repositories/FaqsRepository'
import BrandRepository from '~/repositories/BrandRepository'
import TransactionsRepository from '~/repositories/TransactionsRepository'
import PartnersRepository from '~/repositories/PartnerRepository'
import LeadRepository from '~/repositories/LeadRepository'
import PageRepository from '~/repositories/PageRepository'
import PopupRepository from '~/repositories/PopupRepository'
import BannerRepository from '~/repositories/BannerRepository'
import FinanceLoanRepository from '~/repositories/FinanceLoanRepository'
import NotificationRepository from '~/repositories/NotificationRepository'
import WarrantyRepository from '~/repositories/WarrantyRepository'

export default $axios => ({
	store: StoreRepository($axios),
	news: NewsRepository($axios),
	users: UserRepository($axios),
	categories: CategoryRepository($axios),
	comments: CommentRepository($axios),
	faqs: FaqsRepository($axios),
	brand: BrandRepository($axios),
	transactions: TransactionsRepository($axios),
	partners: PartnersRepository($axios),
	leads: LeadRepository($axios),
	pages: PageRepository($axios),
	popups: PopupRepository($axios),
	banners: BannerRepository($axios),
	financeLoans: FinanceLoanRepository($axios),
	notifications: NotificationRepository($axios),
	warranty: WarrantyRepository($axios),
})
