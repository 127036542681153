const getDefaultState = () => {
	return {
		qMessage: false,
		type: null,
		body: null,
		closeBtn: null,
		timeout: null,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getQMessage: state => state.qMessage,
	getType: state => state.type,
	getBody: state => state.body,
	getCloseBtn: state => state.closeBtn,
	getTimeout: state => state.timeout,
}

const mutations = {
	/**
	 * set state to default
	 */
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	/**
	 * open snakebar
	 * with different type, message and timeout
	 * @param {boolean} qMessage on/off snackbar
	 * @param {string} type with 4 type success/info/warn/error
	 * @param {string} body snake's body message
	 * @param {boolean} closeBtn snake's close btn
	 * @param {number} timeout snake's timeout
	 */
	SET_QUICK_MESSAGE(state, payload) {
		const { qMessage, type, body, closeBtn, timeout } = payload
		state.qMessage = qMessage || false
		state.type = type || null
		state.body = body || null
		state.closeBtn = closeBtn || false
		state.timeout = parseInt(timeout) || 1000
	},
	/**
	 * set state.dialog to false
	 */
	SET_CLOSE_QUICK_MESSAGE(state, value) {
		state.qMessage = value
	},
}

const actions = {
	/**
	 * action open snake
	 * reset last state when open
	 */
	setQMessage({ commit }, payload) {
		// commit('RESET_STATE')
		commit('SET_QUICK_MESSAGE', payload)
	},
	/**
	 * action close alert popup
	 */
	setCloseQMessage({ commit }) {
		commit('SET_CLOSE_QUICK_MESSAGE')
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
