/* eslint-disable no-empty-pattern */
import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		product: null,
		products: [],
		loading: false,
		// processSuccess: false,
		pagination: {
			current: 1,
			total: 0,
			perPage: 50,
		},
		productGallery: null,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getProducts: state => state.products,
	getLoading: state => state.loading,
	// getProcessSuccess: state => state.processSuccess,
	getPagination: state => state.pagination,
	getCurrentPage: state => state.pagination.current,
	getProductGallery: state => state.productGallery,
}

const actions = {
	async fetch({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('product-fetch-v2'), {
				params: {
					...payload.params,
					count: 50,
				},
			})
			if (res.status === 200) {
				const data = res.data.data
				commit('setProducts', data)
				commit('setPagination', {
					current: res.data.current,
					total: res.data.total,
					perPage: 50,
				})
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async create({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(
				Api('product-create'),
				payload.form,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			if (res.status === 200) {
				const data = res.data
				if (data.result_code === 203 || data.result_code === 11205) {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'error',
							message: data.result,
							title: 'error',
							positiveCb: () => {
								this.dialog = false
							},
						},
						{ root: true }
					)
				} else {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'success',
							message: 'Product register successfully!',
							title: 'Successfully',
							positiveCb: () => {
								this.$router.go(this.$router.currentRoute)
							},
						},
						{ root: true }
					)
					commit('setProduct', {
						data,
					})
				}
				// commit('setProcessSuccess', true)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async createProductStation({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(
				Api('product-create'),
				payload.form,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			if (res.status === 200) {
				const data = res.data
				if (data?.result_code !== 1) {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'error',
							message: data.result,
							title: 'Error',
							positiveCb: () => {
								this.dialog = false
							},
						},
						{ root: true }
					)
				} else {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'success',
							message: 'Product register successfully!',
							title: 'Successfully',
							positiveCb: () => {
								this.$router.push({
									name: 'stations-id-edit',
									params: { id: res.data.data.id },
								})
							},
						},
						{ root: true }
					)
					commit('setProduct', {
						data,
					})
				}
				// commit('setProcessSuccess', true)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},

	async updateStationProduct({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.post(
				Api('update-station-product', payload.id),
				payload.form,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			)
			if (res.status === 200) {
				const data = res.data
				if (data.result_code === 1) {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'success',
							message: 'Edit successfully station!',
							title: 'Successfully',
							positiveCb: () => {
								window.location.reload()
							},
						},
						{ root: true }
					)
					commit('setProduct', {
						data,
					})
				} else {
					dispatch(
						'alert/setAlert',
						{
							dialog: true,
							type: 'error',
							message: data.result,
							title: 'error',
							positiveCb: () => {
								this.dialog = false
							},
						},
						{ root: true }
					)
				}
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},

	async approve({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(
				Api('product-approve', payload.productId)
			)
			commit('setProductsItem', {
				data: res.data.data,
				index: payload.index,
			})
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async delete({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.delete(
				Api('product-delete', payload.productId)
			)
			commit('setProductsItem', {
				data: res.data.data,
				index: payload.index,
			})
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async restore({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.put(
				Api('product-restore', payload.productId)
			)
			commit('setProductsItem', {
				data: res.data.data,
				index: payload.index,
			})
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async update({ commit, dispatch }, payload) {
		try {
			commit('setLoading', true)
			await this.$axios.put(Api('product-update-v3', payload.productId), {
				...payload.body,
			})
			commit('setProductsItem', {
				data: payload.body,
				index: payload.index,
			})
		} catch (err) {
			dispatch(
				'common/setAlert',
				{
					dialog: true,
					type: 'error',
					messages: err.data ? err.data.result : err,
				},
				{ root: true }
			)
		} finally {
			commit('setLoading', false)
		}
	},

	async productDetail({ commit }, param) {
		try {
			const res = await this.$axios.get(Api('product-detail', param))
			if (res.status === 200 && res.data) {
				commit('SET_PRODUCT_GALLERY', res.data.data.images)
			}
		} catch (error) {}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setProduct(state, payload) {
		state.product = { ...payload.data }
	},
	setProducts(state, data) {
		state.products = data
	},
	setProductsItem(state, payload) {
		Object.assign(state.products[payload.index], payload.data)
	},
	setPagination(state, payload) {
		state.pagination = { ...payload }
	},
	setCurrentPage(state, payload) {
		state.pagination.current = payload.current
	},
	setLoading(state, loading) {
		state.loading = loading
	},
	// setProcessSuccess(state, data) {
	// 	state.processSuccess = data
	// },

	SET_PRODUCT_GALLERY(state, payload) {
		state.productGallery = payload
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
