<template>
	<v-tabs
		v-model="tab"
		v-bind="attrs"
		:value="value"
		height="50px"
		show-arrows
		slider-color="#000000"
		class="okxe-tabs"
		v-on="listeners"
		@change="onChange"
	>
		<v-tabs-slider style="margin-top:-2px" />
		<v-btn
			height="48px"
			tile
			depressed
			class="pa-3"
			color="#FFFFFF"
			@click="onClickBtnPreviousTabs"
		>
			<v-icon>mdi-chevron-left</v-icon>
		</v-btn>
		<v-divider v-if="!isVisibleBtnPreviousTabs" vertical class="mr-10" />
		<nuxt-link
			v-for="item in items"
			:key="item.id"
			:to="{
				name: item.url.name,
				params: item.url.params,
				query: item.url.query,
			}"
		>
			<v-tab :disabled="isDisabled" @click="onClickTab">
				{{ item.title }}
			</v-tab>
		</nuxt-link>
		<v-divider v-if="!isVisibleBtnNextTabs" vertical class="ml-10" />
		<v-btn
			v-if="!isVisibleBtnNextTabs"
			height="48px"
			tile
			depressed
			class="pa-3"
			color="#FFFFFF"
			:disabled="isDisableBtnNextTabs"
			@click="onClickBtnNextTabs"
		>
			<v-icon>mdi-chevron-right</v-icon>
		</v-btn>
		<v-divider v-if="!isVisibleBtnNextTabs" vertical />
	</v-tabs>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
export default defineComponent({
	name: 'OkxeTabs',
	props: {
		// eslint-disable-next-line vue/require-default-prop
		showBtnPrevious: {
			type: Boolean,
			default: true,
		},
		value: [Number, String],
		items: {
			type: Array || Object,
			default: () => [
				{
					title: 'Transaction Detail',
					url: {
						name: 'transactions-transactions-details',
						query: {
							type: 'cash',
							id: 1,
							tab: 'transactions-details',
						},
					},
				},
				{
					title: 'E-Contract',
					url: {
						name: 'transactions-transactions-details',
						query: { type: 'cash', id: 1, tab: 'e-contract' },
					},
				},
				{
					title: 'Paperwork',
					url: {
						name: 'transactions-transactions-details',
						query: { type: 'cash', id: 1, tab: 'paperwork' },
					},
				},
				{
					title: 'Disbursement Information',
					url: {
						name: 'transactions-transactions-details',
						query: {
							type: 'cash',
							id: 1,
							tab: 'disbursement-information',
						},
					},
				},
			],
		},
		onClickTab: {
			type: Function,
			default: () => {},
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isVisibleBtnPreviousTabs: {
			type: Boolean,
			default: false,
		},
		isVisibleBtnNextTabs: {
			type: Boolean,
			default: false,
		},
		tabActive: {
			type: Number,
			default: 0,
		},
	},
	setup(props, { root: _this, attrs, listeners, emit }) {
		const tab = ref(props.tabActive)
		const currentTabIndex = ref(props.tabActive)
		/* Click Tab */
		const onChange = index => {
			tab.value = index
			currentTabIndex.value = index
			emit('input', index)
		}
		/* Click Next / Previous Tab */
		const isDisableBtnPreviousTabs = computed(
			() => currentTabIndex.value === 0
		)
		const onClickBtnPreviousTabs = () => {
			_this.$router.push({ name: 'transactions' })
			// currentTabIndex.value -= 1
			// tab.value = currentTabIndex.value
			// const url = props.items[currentTabIndex.value].url
			// _this.$router.push({
			//     name: url.name,
			// 	query: url.query
			// })
			// emit('input', currentTabIndex.value)
		}

		const isDisableBtnNextTabs = computed(
			() => currentTabIndex.value === props.items.length - 1
		)
		const onClickBtnNextTabs = () => {
			currentTabIndex.value += 1
			tab.value = currentTabIndex.value
			const url = props.items[currentTabIndex.value].url
			_this.$router.push({
				name: url.name,
				query: url.query,
			})
			emit('input', currentTabIndex.value)
		}
		return {
			tab,
			onChange,
			onClickBtnPreviousTabs,
			onClickBtnNextTabs,
			isDisableBtnPreviousTabs,
			isDisableBtnNextTabs,
			attrs,
			listeners,
		}
	},
})
</script>
