import Api from '~/helper/api'

const getDefaultState = () => {
	return {
		userInfo: {
			id: null,
			nickname: null,
			email: null,
			avatar: null,
			username: null,
			role: null,
		},
	}
}

const state = () => getDefaultState()

const getters = {
	getUserInfo: state => state.userInfo,
}

const actions = {
	// async fetch({ commit }, userId) {
	// 	try {
	// 		const resUser = await this.$axios.$get(
	// 			`/users/${userId}`
	// 		)
	// 		console.log(resUser)
	// 		// commit(TYPE.FETCH_INITIAL_USER_DATA, resUser)
	// 	} catch (err) {
	// 		// console.log(err)
	// 	}
	// },

	async getUserInfo({ commit }, payload) {
		try {
			const res = await this.$axios.get(Api('users-get-info'), {
				headers: {
					Authorization: `Bearer ${payload.token}`,
				},
			})
			commit('user/setUserInfo', {
				data: res.data.data,
			})
		} catch (err) {
			console.log(err)
		}
	},
	// async [TYPE.POSTSTATUS]({ dispatch }, payload) {
	// 	try {
	// 		const res = await this.$axios.$post('/api/v1/users-posts', payload)
	// 		console.log(res)
	// 		// this.$router.push({ name: 'user-id', params: { id: userId } })
	// 		location.reload()
	// 	} catch (err) {
	// 		console.log(err)
	// 	}
	// }
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setUserInfo(state, payload) {
		state.userInfo = { ...payload.data }
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
