/* eslint-disable vue/require-prop-types */
import { Line } from 'vue-chartjs'
import Vue from 'vue'

Vue.component('MyLine', {
	extends: Line,
	props: ['options', 'data'],
	mounted() {
		this.renderChart(this.mydata, this.options)
	},
})
