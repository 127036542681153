import Vue from 'vue'
Vue.directive('permission', {
	inserted(el, binding, vnode) {
		const userInfo = binding.value?.userInfo
		const permission = binding.value?.permission
		const typeOfPer = typeof permission
		if (
			userInfo.role_has_permission !== 1 &&
			!userInfo.role_has_permission?.some(x => {
				if (typeOfPer === 'object') {
					return permission.includes(x.name)
				}
				return x.name === permission
			})
		) {
			el.parentNode.removeChild(el)
		}
	},
})

Vue.directive('disabled', {
	inserted(el, binding, vnode) {
		const userInfo = binding.value?.userInfo
		const permission = binding.value?.permission
		if (
			userInfo.role_has_permission !== 1 &&
			!userInfo.role_has_permission?.some(x => {
				return x.name === permission
			})
		) {
			el.setAttribute('disabled', 'disabled')
		}
	},
})
