import Api from '~/helper/api'
const getDefaultState = () => {
	return {
		managers: [],
		loading: false,
	}
}

const state = () => ({
	...getDefaultState(),
})

const getters = {
	getManagers: state => state.managers,
	getLoading: state => state.loading,
}

const actions = {
	async fetch({ commit }) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.get(Api('manager-fetch'))
			if (res.status === 200) {
				const data = res.data.data
				commit('setManagers', data)
			}
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
	async create({ commit }, payload) {
		try {
			commit('setLoading', true)
			const res = await this.$axios.$post(Api('manager-create'), {
				email: payload.email,
			})
			console.log(res)
		} catch (err) {
			console.log(err)
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	resetState(state) {
		Object.assign(state, getDefaultState())
	},
	setManagers(state, data) {
		state.managers = [...data]
	},
	setLoading(state, loading) {
		state.loading = loading
	},
}

export default {
	state,
	actions,
	mutations,
	getters,
}
