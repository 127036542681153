const resource = '/category'
export default $axios => ({
	fetch() {
		return $axios.get(`${resource}`)
	},

	show(id, payload) {
		return $axios.get(`${resource}/${id}`, payload)
	},

	create(payload, setting) {
		return $axios.post(`${resource}`, payload, setting)
	},

	update(id, payload) {
		return $axios.post(`${resource}/${id}`, payload)
	},

	patch(id, payload) {
		return $axios.patch(`${resource}/${id}`, payload)
	},

	delete(id) {
		return $axios.delete(`${resource}/${id}`)
	},
})
