<template>
	<v-dialog v-model="dialog" persistent max-width="390">
		<v-card>
			<v-card-title class="headline grey lighten-2">
				<alert-title :type="type" />
			</v-card-title>
			<v-card-text class="py-5">
				{{ messages }}
			</v-card-text>
			<v-card-actions class="px-6 pb-5">
				<v-btn
					depressed
					block
					color="#00bcc3"
					style="color: #fff"
					@click="closeDialog"
				>
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import AlertTitle from '~/components/alert/AlertTitleComponent'

export default {
	components: {
		AlertTitle,
	},
	computed: {
		dialog() {
			return this.$store.getters['common/getDialog']
		},

		type() {
			return this.$store.getters['common/getType']
		},

		messages() {
			return this.$store.getters['common/getMessages']
		},

		fbAction() {
			return this.$store.getters['common/getFbAction']
		},
	},

	methods: {
		closeDialog() {
			if (this.fbAction) {
				this.fbAction()
				// return false
				// why return false?? this is just callback function, should always close popup after do it
			}

			this.$store.commit('common/SET_ALERT', { dialog: false })
		},
	},
}
</script>
